
.find_link_page_contaainer {
  margin: 3% 1%;
  /* background-color: var(--tableBody-temp); */
  border-radius: 10px;
  }
  .find_link_page_contaainer .tab_content_container .header_with_buttons_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .find_link_page_contaainer .tab_content_container .header_with_buttons_container .header_and_go_back_button_container{
    display: flex;
    gap: 15px;
    align-items: center;
  }

  .find_link_page_contaainer .tab_content_container .header_with_buttons_container .header_and_go_back_button_container .go_back_button{
    background: var(--background-paper);
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    cursor: pointer;
  }

  

  
  .find_link_page_contaainer .tab_content_container .header_container{
    display: flex;
    flex-direction: column;
    gap: 5px;
    /* margin: 1% 0; */
    text-align: left;
  }
  .find_link_page_contaainer .tab_content_container .header_container .header {
    font-size: var(--heading-font-size);
  }

  .find_link_page_contaainer .tab_content_container .header_container .sub_header {
    font-size: var(--text-font-size);
  }

  /* CSS */
.find_link_page_contaainer .tab_content_container .button_container {
  display: flex;
  gap: 10px; /* Space between the buttons */
}


  .find_link_page_contaainer .tab_content_container .main_tab_content_container  {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 1% 0;
    background-color: var(--tableBody-temp);
    padding: 1%;
  }
  
.main_tab_content_container .settings_and_keywords_filter_container  {
    display: flex;
    gap: 20px;
    margin: 1%;
    /* background-color: var(--background-default); */
  }
 .main_tab_content_container .settings_and_keywords_filter_container .keyword_filter , .main_tab_content_container .settings_and_keywords_filter_container .settings_section   {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 49%;
    height: 200px;
    border-radius: 10px;
    padding: 20px;
    background-color: var(--background-default);
    text-align: left;
  }
  .settings_and_keywords_filter_container .keyword_filter .header , .settings_and_keywords_filter_container .settings_section .header  {
    font-size: var(--text-font-size);
    font-weight: bold;
  }
  .settings_and_keywords_filter_container .keyword_filter .checkbox_text{
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .settings_and_keywords_filter_container .settings_section .form_container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px; /* equivalent to spacing={2} */
    margin-top: 0.5rem;
    width: 100%;
  }
  .main_tab_content_container .form_label_container{
    border: 1px solid #847d7d;
    border-radius: 10px;
  }
  .settings_and_keywords_filter_container .settings_section .form_item {
    width: 44%; /* Take 45% width for each item to mimic xs={6} */
  }
  .settings_and_keywords_filter_container .settings_section .form_item .form_heading{
    font-size: 11px;
    margin: 0 0 10px 0;
  }
  
  .find_link_page_contaainer .tab_content_container  .page_type_confiq_conainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 10px;
  }

  .find_link_page_contaainer .tab_content_container  .page_type_confiq_conainer .form_container{
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: space-between;
    gap: 7px;
  }
  .find_link_page_contaainer .tab_content_container  .page_type_confiq_conainer .form_container .header_container{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .find_link_page_contaainer .tab_content_container  .page_type_confiq_conainer .form_container .header_container .header{
    font-size: 18px;
  }
  .find_link_page_contaainer .tab_content_container  .page_type_confiq_conainer .form_container .header_container .sub_header{
    font-size: 14px;
  }
  .find_link_page_contaainer .tab_content_container  .page_type_confiq_conainer .form_container .handle_url_and_search_bar_container{
    display: flex;
    justify-content: space-between;
  }
  .find_link_page_contaainer .tab_content_container  .page_type_confiq_conainer .search_bar_content{
    display: flex;
    justify-content: space-between;
    gap: 5px;
    width: 48%;
    align-items: flex-end;
    padding: 0 10px;
  }

  .find_link_page_contaainer .tab_content_container .search_bar_container {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border: 1px solid var(--tableBorder-border); /* Border color */
    border-radius: 30px; /* Rounded corners */
    background-color: transparent; /* Transparent background */
    width: 100%;
}

.find_link_page_contaainer .tab_content_container .search_icon {
    font-size: 18px; /* Icon size */
    margin-right: 8px;
}

.find_link_page_contaainer .tab_content_container .search_input {
    border: none;
    outline: none;
    width: 100%;
    font-size: 16px;
    background-color: transparent; /* Transparent background */
    caret-color: #239cff; /* Caret color */
    font-family: inherit; /* Inherit font style */
    color: var(--text-primary);
}

.find_link_page_contaainer .tab_content_container .search_input::placeholder {
    opacity: 0.8;
}


.find_link_page_contaainer .tab_content_container .check_box_container {
  display: flex;
  justify-content: center;
  background-color: var(--background-default);
  border-radius: 10px;
  padding: 1%;
}

.find_link_page_contaainer .tab_content_container .form_group_grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
}
.find_link_page_contaainer .tab_content_container .opportunity_match{
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 1%;
}

.find_link_page_contaainer .tab_content_container .opportunity_match .header_container{
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* margin: 1% 0; */
  text-align: left;
}
.find_link_page_contaainer .tab_content_container .opportunity_match .header_container .header {
  font-size: var(--heading-font-size);
}

.find_link_page_contaainer .tab_content_container .opportunity_match .header_container .sub_header {
  font-size: var(--text-font-size);
}
.find_link_page_contaainer .tab_content_container .opportunity_match .slider_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 70%;
  border: 1px solid var(--tableBorder-border);
  border-radius: 10px;
  padding: 2%;
  text-align: left;
}

.filter-buttons {
  display: flex;
  gap: 30px;
}

.filter-button {
  padding: 8px 16px;
  border-radius: 8px;
  background-color: var(--background-default); /* dark background */
  font-family: Arial, sans-serif;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  color: var(--text-primary);
  transition: background-color 0.3s ease;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-button:hover {
  background-color: #239cff; /* slightly lighter on hover */
  color: var(--background-default);

}

.filter-button.active {
  color: var(--background-default);
  background-color: #239cff; /* active blue background */
}


.opportunity_match_check_box_container , .page_type_check_box_container{
  display: flex;
  align-items: center;

}


.page_type_check_box_container span {
  padding: 5px 5px 3px 0;
}

.return_button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:  var(--background-body); /* Dark background */
  color: var(--logo-text); /* White text */
  padding: 8px 16px; /* Spacing */
  border-radius: 8px; /* Rounded corners */
  font-size: 16px; /* Text size */
  cursor: pointer;
  gap: 8px; /* Space between text and icon */
  border: 1px solid var(--border-primary);
  width: 222px;
    height: 44px;
}



.return_button span {
  margin-right: 8px; /* Spacing for text */
}