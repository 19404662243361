/* .components_Container
{
  margin-left: 50px;
}

.flow_toggle_container
{
  position: absolute;
  top: 55px;
  border: 1px solid #aeaeae;
  margin: 7px;
  border-radius: 3px;
  box-shadow: 1px 1px 4px 0px #5e5e5e;
} */
.flow_toggle_button
{
  width: 35px;
  height: 38px;
  margin: 5px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position-x: center;
  cursor: pointer;
}
.flow_toggle_button.active {
    display: none;
}

.component-transition {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}

.component-transition.detached {
  opacity: 0;
}
