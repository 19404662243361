#customized-table-container {
  margin-top: 20px;
    height: 220px;
    width: 445px;
    position: relative;
    overflow: hidden;
/* Hide overflow */
}

#customized-table-header {
  background-color: var(--tableHeader-temp);
  position: sticky;
  top: 0; /* Stick to the top */
  z-index: 1; /* Ensure the header stays above the table body */
}

#customized-table-body {
  overflow-y: auto; /* Enable vertical scrolling */
  height: calc(100% - 48px); /* Adjust height to fit within the container minus header height */
  background-color: var(--tableBody-temp);
}

#customized-table {
  width: 100%; /* Take full width of the container */
}

#customized-table-cell {
  font-size: 14px;
  width: auto; /* Set table cells to automatically adjust width */
}

/* Media query for responsiveness */
@media screen and (max-width: 600px) {
  #customized-table-cell {
    font-size: 12px; /* Adjust font size for smaller screens */
  }
}
