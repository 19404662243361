/* TableWithPagination.css */
.TableWithPagination_section {
    /* padding-top: 30px; */
    /* margin-top: 10px; */
    justify-content: center;
    display: inline-block;
    border-radius: 30px;
    width: 100%;
  }
  @media (max-width: 1289px) {
    .TableWithPagination_section {
      width: 1150px; /* Adjust width for screens up to 786px */
    }
  }
  @media (max-width: 1230px) {
    .TableWithPagination_section {
      width: 1117px; /* Adjust width for screens up to 786px */
    }
  }
  @media (max-width: 1155px) {
    .TableWithPagination_section {
      width: 1000px; /* Adjust width for screens up to 786px */
    }
  }
  @media (max-width: 1120px) {
    .TableWithPagination_section {
      width: 950px; /* Adjust width for screens up to 786px */
    }
  }
  @media (max-width: 1000px) {
    .TableWithPagination_section {
      width: 905px; /* Adjust width for screens up to 786px */
    }
  }
  @media (max-width: 920px) {
    .TableWithPagination_section {
      width: 800px; /* Adjust width for screens up to 786px */
    }
  }
  @media (max-width: 786px) {
    .TableWithPagination_section {
      width: 600px; /* Adjust width for screens up to 786px */
    }
  }
  
  @media (max-width: 600px) {
    .TableWithPagination_section {
      width: 520px; /* Adjust width for screens up to 600px */
    }
  }
  
  @media (max-width: 500px) {
    .TableWithPagination_section {
      width: 360px; /* Adjust width for screens up to 500px */
    }
  }
  .table_body_with_bulk_section {
    border-radius: 15px;
    padding: 0 15px;
    background: var(--tableBody-temp);
    color: var(--text-primary);
  }
  /* Container styling */
  .table_container {
    /* border: 1px solid; */
    /* border-radius: 8px; */
    margin-bottom: 20px;
    /* box-shadow:0 2px 2px 2px rgba(0, 0, 0, 0.1); */
    overflow-y: auto;
    width: 100%;
    overflow-x: auto;
    position: relative;
  }
  
  .check_box_place {
    display: block;
    width: 45px;
  }
  .check_box_place_search_bar{
    display: block;
    width: 45px;
  }
  .check_box_place_search_bar:empty:before {
    content: '\00a0'; /* This adds a non-breaking space */
  }
  .check_box_place_search_bar:empty {
    display: none;
  }
    
    
  .bulk_action_section {
    display: flex;
    justify-content: left;
    align-items: center; /* Vertically center items */
    padding-top: 16px;
  }
  .bulk_action_container{
    display: flex;
    align-items: center;
    padding: 10px 5px;
    gap: 15px;
  }
  .bulk_action {
    /* margin: 0 10px;  */
    display: flex;
    align-items: center;
  }
  
  .bulk_action .download-icon {
    padding: 10px;
    border-radius: 50%; /* Make it circular */
    /* transition: background-color 0.3s ease; */
  }
  
  /* .bulk_action .download-icon:hover {
    background-color: #0056b3;
  } */
  
  /* Header row styling */
  .table_header {
    display: flex;
    width: fit-content;
    min-width: 100%;
    border-radius: 7px;
    font-weight: bold;
    font-size: var(--text-font-size);
    width: fit-content;
    background-color: var(--tableHeader-head);
  }
  
  /* Table cell styling */
  .table_cell {
    height: 53px;
    display: flex;
    flex: 0 0 auto;
    font-size: var(--text-font-size);
    /* padding: 16px; */
    text-align: center;
    /* color: #555; */
    justify-content: space-between;
  }
  .table_cell_left_cell {
    display: flex;
  }
  
  .table_cell .table_header_text {
    margin-right: 8px;
    flex-grow: 1;
    text-align: justify;
    margin: auto;
    padding: 0px 0px 0px 15px;
    font-size: var(--text-font-size);
  }
  
  .table_sort {
    /* text-align: right; */
    margin: auto 15px auto 0px;
  }
  .table_sort_option{
    display: flex;
    flex-direction: column;
    font-size: 6px;
  }
  .table_sort_option {
  }
  .table_sort_option,
  .table_sorted {
    transition: color 0.2s ease;
    display: flex;
    flex-direction: column;
    font-size: 6px;
    margin: 0 0px 0px 6px;
  }
  .table_cell:hover .table_sort_option,
  .table_cell:hover .table_sorted {
    color: #007bff;
    cursor: pointer;
  }
  .table_cell_text {
    /* display: block;
    margin: auto 0;
    padding: 0px 0px 0px 15px; */
    overflow: hidden;
    height: 100%;
    align-content: center;
    color: var(--text-table);
    font-size: var(--text-font-size);
    text-align: left;
  }
  .select_all_data_checkbox_with_select{
    display: flex;
    align-items: center;
    /* width: 50px; */
    height: 100%;
    width: 100%;
    justify-content: right;
    gap: 7px;
  }
  
  .select_all_data_checkbox_with_select_option{
    display: flex;
    align-items: center;
    height: 100%;
  }
  .table_cell .select_all_data_checkbox {
    width: 100%;

  }
  .table_cell .select_all_data_inCurrentPage_checkbox {
    width: 100%;
    height: 38%;
    display: flex;
  }
  
  .table_body_outer {
    position: relative; /* Ensure relative positioning for absolute child */
  }
  
  .table_body_scroll {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 20px;
    overflow-y: auto;
    overflow-x: auto;
    z-index: 1;
  }
  /* @media screen and (max-width: 480px) {
    .table_body_scroll {
      overflow-y: hidden;
      overflow-x: hidden;
    }
  }
  
  @media screen and (min-width: 481px) and (max-width: 768px) {
    .table_body_scroll {
      overflow-y: hidden;
      overflow-x: hidden;
    }
  }
  
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .table_body_scroll {
      overflow-y: hidden;
      overflow-x: hidden;
    }
  }
  

  @media screen and (min-width: 1025px) and (max-width: 1100px) {
    .table_body_scroll {
      top: 51%;
      right: 4%;
    }
  }

  @media screen and (min-width: 1101px) and (max-width: 1155px) {
    .table_body_scroll {
      top: 271px;
      right: 45px;
    }
  }
  
  @media screen and (min-width: 1156px) and (max-width: 1240px) {
    .table_body_scroll {
      top: 278px;
      right: 25px;
    }
  }
  

  @media screen and (min-width: 1281px) and (max-width: 1440px) {
    .table_body_scroll {
      top: 291px;
      right: 34px;
    }
  }
  
  @media screen and (min-width: 1441px) and (max-width: 1600px) {
    .table_body_scroll {
      top: 300px;
      right: 43px;
    }
  }
  
  @media screen and (min-width: 1601px) and (max-width: 1760px) {
    .table_body_scroll {
      top: 316px;
      right: 46px;
    }
  }
  
  @media screen and (min-width: 1761px) and (max-width: 1920px) {
    .table_body_scroll {
      top: 337px;
      right: 44px;
    }
  }
  

  @media screen and (min-width: 1921px) and (max-width: 2050px) {
    .table_body_scroll {
      top: 348px;
      right: 53px;
    }
  }
  
  @media screen and (min-width: 2051px) and (max-width: 2310px) {
    .table_body_scroll {
      top: 366px;
      right: 59px;
    }
  }
  
  @media screen and (min-width: 2311px) and (max-width: 2560px) {
    .table_body_scroll {
      top: 348px;
      right: 51px;
    }
  }
  
  @media screen and (min-width: 2561px) and (max-width: 3840px) {
    .table_body_scroll {
      top: 434px;
      right: 75px;
    }
  }
  
  @media screen and (min-width: 3841px) and (max-width: 5120px) {
    .table_body_scroll {
      top: 562px;
      right: 102px;
    }
  }
  
  @media screen and (min-width: 5121px) and (max-width: 6500px) {
    .table_body_scroll {
      top: 688px;
      right: 129px;
    }
  } */
/*  
  .table_body_scroll::-webkit-scrollbar {
    width: 7x; 
  }
  
  .table_body_scroll::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  
  .table_body_scroll::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  .table_body_scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  */
  
  .table_body {
    max-height: 370px;
    overflow-y: auto;
    overflow-x: hidden;
    width: fit-content;
    min-width: 100%;
    height: 400px;
    scrollbar-width: none;
  }
  
  .table_body_container {
    overflow-y: hidden;
    max-width: 100%;
    overflow-x: auto; /* Add horizontal scrollbar when content overflows */
  }

  /*
  .table_body_container::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  
  .table_body_container::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  
  .table_body_container::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  .table_body_container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  */
  /* .table_cell input[type="checkbox"], */
  .table_cell .tooltip-container {
    margin: 0px 15px 11px;
  }
  .table_cell input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 15px;
    height: 15px;
    background-color: var(--tableBody-temp);
    border: 2px solid var(--text-primary);
    border-radius: 3px;
    margin-right: 0;
  }
  /* .table_cell input[type="checkbox"]:checked {
    margin-bottom: 7px;
  } */
  .table_cell input[type="checkbox"]:checked::after {
    content: "\2713";
    display: block;
    text-align: center;
    color: var(--text-primary);
    font-size: 10px;
    /* line-height: 1; */
    padding: 0px 0px 2px 2px;
    font-weight: bolder;
  }
  
  .table_cell input[type="checkbox"]::before {
    background-color: var(--tableBody-temp);
  }
  
  /* Optional: Style the label text associated with the checkbox */
  .table_cell .checkbox_label {
    margin-left: 5px; /* Adjust margin as needed */
    font-size: 14px; /* Adjust font size as needed */
  }
  .check_box_table_body {
    padding-top: 20px;
    padding-left: 4px;
  }
  

  
  .table_cell .tooltip-container {
    margin: auto;
  }
  .table_cell .select_all_data_checkbox .tooltip-container .tooltip {
    left: 70px;
  }
  .table_cell
    .select_all_data_inCurrentPage_checkbox
    .tooltip-container
    .tooltip {
    left: 120px;
  }
  
  .column_resize_handle,
  .horizontal_separator_icon {
    width: 18px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .column_resize_handle {
    cursor: col-resize;
    padding-top: 16px;
  }
  
  /* Body and row styling */
  
  .table_row {
    width: fit-content;
    min-width: 100%;
    display: flex;
    border-bottom: 1px solid var(--tableBorder-border);
  }
  
  .per_page_setup_section_select,
  option {
    background: var(--header-secondary);
    color: var(--text-primary);
  }
  .bulk_action_section {
    display: flex;
    justify-content: space-between;
  }
  
  .download_icon {
    width: 35px;
    height: 35px;
    background-size: 70% 70%;
    background-position: center;
    cursor: pointer;
    display: inline-block;
    padding-left: 20px;
  }
  
  .search_bar_section {
    height: 80ox;
  }
  
  .search_bar {
    flex: 0 0 auto;
    margin: 1px;
    border-radius: 5px;
    border: 1px solid var(--tableBorder-border);
  }
  .reset_font {
    font-size: var(--text-font-size);
  }
  
  #search_bar_box {
    background: var(--tableBody-temp);
    color: var(--text-primary);
  }
  #search_bar_reset_button {
    width: 110px;
    height: 41px;
    color: var(--text-primary);
  }
  .custom_label_color {
    color: var(--search-label);
    padding-bottom: 0px;
  }
  #input_min_max_search {
    padding: 14px 2px 5px;
  }
  /* .search_bar:hover {
  } */
  /* .action_bar_button{
    height: 44px;
    border: 1px solid #7a7a7a ;
    border-radius: 5px;
  } */
  .action_bar_button:hover {
    border: 1px solid var(--text-primary);
  }
  
  .search_bar input[type="text"] {
    /* width: 300px; */
    padding: 10px;
    border: none;
    font-size: var(--text-font-size);
  }
  
  /* .search_bar:hover input[type="text"] {
    width: 350px; 
  } */
  
  .search_bar input[type="text"]:focus {
    outline: none;
  }
  
  .action_bar {
    pointer-events: none;
  }
  
  .pagination_main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 19px 0px;
    /* font-family: Arial, sans-serif;  */
  }
  .per_page_setup_section {
    display: flex;
    width: 20%;
    align-items: center;
  }
  .per_page_setup_section select {
    padding: 2px;
    border: 1px solid #64b5f6;
    border-radius: 5px;
    background-color: transparent;
    cursor: pointer;
    transition: border-color 0.3s ease;
    height: 27px;
  }
  .per_page_setup_section_text {
    font-size: var(--text-font-size);
    padding: 0 12px;
  }
  
  .per_page_setup_section select:focus {
    outline: none;
    border-color: #007bff; /* Highlight on focus */
  }
  
  .current_page_description_section {
    font-size: var(--text-font-size);
    display: flex;
    gap: 7px;
    font-weight: 100;
  }

  .skip_section {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding-right: 5px;
  }
  
  .previous_next_icon {
    width: 25px;
    height: 25px;
    background-size: 100%;
    cursor: pointer;
    color: var(--text-primary);
  }
  .previous_next_icon_active {
    height: 34px;
    width: 28px;
    margin: 5px 4px 0;
    background: var(--tableHeader-head);
    border-radius: 6px;
  }
  .previous_next_icon_disable {
    height: 34px;
    width: 28px;
    margin: 5px 4px 0;
    background: var(--tableHeader-head);
    border-radius: 6px;
  }
  .previous_next_icon_icon {
    margin-top: 8px;
  }
  
  .pagination .skip_pages {
    width: 60px;
    height: 25px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 0 10px;
    background-color: #f8f8f8;
    transition: border-color 0.3s ease;
  }
  
  .pagination .skip_pages:focus {
    outline: none;
    border-color: #007bff; /* Highlight on focus */
  }
  
  .pagination button {
    padding: 8px 16px;
    border: none;
    /* border-radius: 4px; */
    margin: 0 5px;
    cursor: pointer;
    font-size: 14px;
    background: #007bff;
    color: #fff;
    transition: background-color 0.2s ease;
  }
  
  .pagination button.active {
    background-color: #0056b3;
  }
  
  .pagination button:hover {
    background-color: #0056b3;
  }
  /* .icons{
    font-size: 20px;
  } */
  .download_icons {
    font-size: 27px;
    align-items: flex-end;
    justify-content: right;
    cursor: pointer;
    color: var(--text-primary);
  }
  .action_icons {
    display: inline-flex;
    overflow-x: hidden;
    cursor: pointer;
  }
  #icon_button_table {
    background: var(--background-default);
    color: var(--text-primary);
  }
  
  /* Add media queries for responsive design */
  /* TableWithPagination.css */
  
  /* Add a media query for smaller screens */
  /* Add a media query for smaller screens */
  
  .pagination_data_result {
    font-size: var(--text-font-size);
    color: #64b5f6;
  }
  
  /* Media query for maximum width 786px */
  /* Add media queries for adjusting width only */
  /* Adjust width for screens up to 786px */
  /* @media (max-width: 786px) {
    .TableWithPagination_section {
      width: 100%; 
    }
  } */
  
  /* Adjust width for screens up to 600px */
  /* @media (max-width: 600px) {
    .TableWithPagination_section {
      width: 100%; 
    }
    
  } */
  
  /* Adjust width for screens up to 500px */
  /* @media (max-width: 500px) {
    .TableWithPagination_section {
      width: 100%; 
    }
  } */
  
  .table_form {
    background-color: var(--tableBody-temp);
  }
  #table_form :hover {
    background-color: var(--tableHeader-temp);
  }
  
  .customMenuPaper .MuiMenuItem-root {
    background-color: var(--tableBody-temp); /* Change background color */
    border: 1px solid var(--tableBorder-border); /* Add border */
  }
  
  .customMenuPaper .MuiMenuItem-root:hover {
    background-color: var(
      --tableHeader-head
    ); /* Change background color on hover */
  }
  
  .tooltip-title {
    background-color: var(--tableBody-temp);
    border: 1px solid var(--tableBorder-border);
    color: var(--logo-text);
    display: flex;
    flex-direction: row;
    padding: 18px;
    margin: -5px -8px;
    border-radius: 5px;
  }
  
  #custom-button-color {
    background-color: var(--tableHeader-head); /* Change background color */
    border: 1px solid var(--tableBorder-border);
    color: var(--logo-text);
  }
  
  .css-6hp17o-MuiList-root-MuiMenu-list {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    padding-top: 0x;
    padding-bottom: 0px;
    outline: 0;
  }
  .table_cell_text {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 13px 10px 0px 0px;
    margin-left: 15px;
  }
  
  .tooltip-content {
    background-color: var(--tableHeader-head);
    border: 1px solid var(--tableBorder-border);
    color: var(--logo-text);
    padding: 20px 50px 20px 10px;
    width: 30rem;
    box-sizing: border-box;
    position: relative;
    margin: -4px 0px 0px 0px;
    max-height: 10rem;
    overflow: auto;
    min-width: 100%;
    scrollbar-width: thin;
    border: 2px solid #999; /* Green border */
      border-radius: 8px;
      box-shadow: 0px 4px 8px rgb(0 0 0 / 18%); /* Soft shadow */
  }
  .tooltip-content::before {
    border-top: none !important;
    border-right: none !important;
  }
  .ellipsis:hover + .MuiTooltip-root {
    opacity: 1;
  }
  .ellipsis {
    cursor: pointer;
    color: var(--logo-text);
    font-weight: bold;
    margin: 0;
    text-decoration: none;
  }
  .tooltip-content[data-popper-placement^="right"] {
    margin-top: -5px; /* Adjust this value to align it properly */
  }
  .copy-button {
    cursor: pointer;
    border: none;
    background: transparent;
    color: var(--logo-text);
    outline: none;
    padding: 0;
    position: absolute;
    top: 8px;
    right: 8px;
  }
  
  .copy-button:hover {
    color: #3f51b5;
  }
  
  .loading_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Adjust spacing as needed */
    margin-top: 1%; /* Adjust margin as needed */
    margin-left: 1%;
  }
  
  .skeleton_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Adjust spacing between skeletons as needed */
  }
  
  .circular_progress_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px; /* Adjust margin as needed */
  }
  
  
  
  .css-b2qdzd-MuiTooltip-tooltip {
    background-color: transparent !important;
    padding: 0 !important;
  }


  #menu_of_text_search {
    background-color: var(--search_box-first) ;
    border: 1px solid var(--tableBorder-border) ;
    color: var(--logo-text) !important;
    width: 13rem ;
}

.css-1baqviy-MuiPaper-root-MuiPopover-paper-MuiMenu-paper , .css-rspimq-MuiPaper-root-MuiPopover-paper-MuiMenu-paper  {
    background-color: var(--search_box-first) !important;
    border: 2px solid var(--tableBorder-border) ;
    color: var(--logo-text) !important;
    /* width: 16rem;
    padding: 6px 14px; */
}
.css-q8hpuo-MuiFormControl-root{
    /* background-color: transparent !important; */
    background-color: var(--tableBody-temp) !important;
    /* border: 1px solid var(--tableBorder-border) !important; */
    color: var(--logo-text) !important;
}
.css-1baqviy-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
    background-color: var(--search_box-first) !important;
}

.css-eega6-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root::before ,.css-1wtajbo-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root::before {
    border-bottom: none !important;
    
}
.filter_icon_for_individual_search{
  cursor: pointer;
  margin: 3% 0 0 0;
}


.form_controller_text_search {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds a shadow */

  border-radius: 4px; /* Optional: Adds rounded corners */
  width: 14rem;
  padding: 6px 14px;
}

.form_controller_text_search .custom-select {
    background-color: var(--search_box-first) !important;
    border: 2px solid var(--tableBorder-border) ;
    color: var(--logo-text) !important;
    width: 14rem;
  padding: 8px; /* Example: Add padding */
  border-radius: 4px; /* Example: Add border radius */
}
