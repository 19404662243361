.search_section
{
  margin: 10px 0px 0px 0px;
}



.campaign_detail_section{
  text-align: right;
  margin-bottom: 2%;
  /* margin-right: 2%; */

}







#panel1a_textarea{
  background-color: var(--container-body);
  color: var(--logo-text);
  border-radius: 8px;
}

.tab_header_containers {
  display: flex;
  justify-content: center;
  background-color: var(--tableHeader-head);
  color: var(--logo-text);
  border-radius: 10px;
  overflow: hidden;
}

.tab_header {
  flex: 1;
  padding: 20px 10px;
  margin: 0px 0px 20px 0px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
  text-align: center;
  font-weight: bold;
  color: var(--logo-text);
  background-color: var(--tableBody-temp);
font-size: var(--text-font-size);
  border: none;
  outline: none;
  position: relative;
  z-index: 1;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
}

.tab_header::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
    background-color:  var(--tableBorder-border);;
  transition: background-color 0.3s ease;
  z-index: -1;
}

.tab_header.active {
background-color: transparent;
color: var(--logo-text);
  transform: translateY(+4px);
  box-shadow: none;
}

.tab_header.active::before {
background-color: transparent;
}



.scroller
{
  overflow: scroll;
}
.scroller::-webkit-scrollbar {
  display: none;
}
.tab_content_containers
{
  width: 500%;
  /*margin: 0% 15%;*/
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}
.tab_content_containers .tab_content .accordion
{
  margin: 0px 0px 30px 0px !important;
}
.tab_content > textarea
{
    margin: 5px 0px 5px 0px;
    min-height: 40px !important;
    width: 100%;
    overflow: auto;
}

.tab_content
{
  width: calc( 20% - 80px );
  margin: 0% 25px;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #aaa;
  box-shadow: 2px 2px 6px 0px #aaa;
}

.button_containers
{
  display: flex;
  justify-content: space-evenly;
}
.search_option_section{
  font-size: var(--text-font-size);
}
.button_container
{
  text-align: center;
  margin: 25px 0px 25px 0px;
}
.button_container button{
  font-size: var(--button-font-size);
}
.button_container_apify button, .button_container_serper button 
{
  text-align: center;
  margin: 25px 0px 25px 0px;
  font-size: var(--button-font-size);
}

.button_container_serper {
  /* Your styles for the container div */
}

.serper_search_domain {
  /* Your styles for the FormControl component */
  font-size: var(--text-font-size);
}

.serper_search_domain > .MuiInputLabel-root {
  /* Your styles for the InputLabel component */
  font-size: var(--text-font-size);
}

.serper_search_domain > .MuiSelect-root {
  /* Your styles for the Select component */
  font-size: var(--text-font-size);
}

.serper_search_domain > .MuiList-root > .MuiMenuItem-root {
  /* Your styles for the MenuItem components */
  font-size: var(--text-font-size);
}


.edit_scrap_icon
{
  width: 20px;
  height: 20px;
  margin: auto 0px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position-x: center;
  cursor: pointer;
}
.edit_scrap_icon:hover
{
  width: 23px;
  height: 23px;
}

.delete_button_icon
{
  width: 20px;
  height: 20px;
  margin: auto 26px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position-x: center;
  cursor: pointer;
}

.delete_button_icon:hover
{
  width: 23px;
  height: 23px;
  /*margin-left: 23px;
  margin-top: -5px;
  margin-bottom: -3px;*/
}

.bulk_delete_button
{
  width: 22px;
  height: 22px;
  margin: 0 0 7px 8px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position-x: center;
  cursor: pointer;
}

.bulk_classify_button
{
  width: 36px;
  height: 30px;
  margin: 0px 15px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position-x: center;
  cursor: pointer;
}

.bulk_PageQualityCheck_button
{
  width: 36px;
  height: 30px;
  margin: 0px 15px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position-x: center;
  cursor: pointer;
}


.show_still_searching
{
  opacity: 1;
  float: left;
  display: block;
}
.show_still_searching_fade_out {
  opacity: 0;
  transition: opacity 2.0s ease-in-out;
  float: left;
  animation: hideDiv 1.5s forwards;
}
@keyframes hideDiv {
  to {
    display: none;
  }
}








/******************************************************************************************/





.projects_section {
  display: flex;
  justify-content: center;
  height: 100%;
  margin-top: 25px
}

.projects_container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 80%;
  max-width: 600px;
  padding: 20px;
  box-shadow: 2px 2px 4px 1px #e6e6e6;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
}

.project_list {
width: 100%;
}

.project {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  background-color: var(--tableBody-temp);
  display: flex;
  justify-content: space-between;
}

.project_name
{
    padding: 4px 5px 5px 14px;
}
.project_name_change input
{
    height: 0px;
    width: 90%;
}


.project_name_change
{
  display: flex;
  justify-content: space-between;
}

.new_project {
  margin-top: 20px;
}

.project_detail_section
{
  display: flex;
  justify-content: right;
  margin-bottom: 10px;
}

.breadcrumbs-link {
  text-decoration: none;
  color: var(--logo-text);
}
.breadcrumbs-link:hover{
  text-decoration: underline;
}

/******************************************************************************************/

.editable_scrapped_data_url {
  color: #1565c0;
  font-weight: bold;
  cursor: pointer;
  /* / width: 100%; / */
  padding-bottom: 2%;
}
#Breadcrumb{
 
 
  color:var(--text-primary);
  text-decoration: none;
}
.text_area_of_link_exchange{
   background: var(--container-body);
  color: var(--text-primary);
  /* padding: 16.5px 14px;
  height: 65px;  */
  font: inherit;
  font-size: var(--text-font-size);
  box-sizing: border-box;
  overflow: scroll;
  line-height: 1.5;
  padding: 10px;
  border-radius: 6px 6px 0 6px;
  border: 1px solid gray;
}





.table_action_icon_link_exchange{
  color: var(--logo-color);
}
.table_action_icon_link_exchange_row{
  font-size: var(--table_icon_size);
  color: var(--logo-color);
}
.table_action_icon_link_exchange_row svg{
  font-size: 20px;
  color: var(--logo-color);
}
#LinkExchange_model_box{
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 40%; */
  width: 44%;
  color: var(--text-primary);

  padding: 1%;
  background: var(--background-default);
}
.LinkExchange_modal_header{
  font-size: var(--text-font-size);
  text-align: center;
  margin: 3px auto 6px;
  font-weight: bold;
  font-family: inherit;
  padding: 2px 0px;
}    
.ButtonWithCustomFontSize{
  font-size: var(--button-font-size);
}
/* .LinkExchange_modal_text_heading{
  text-align: center;
  color: red;
}
.LinkExchange_modal_text{
  text-align: center;
  font-family: monospace;
}
.LinkExchange_modal_text_deduct{
  text-align: center;
  color: blue;
  font-family: monospace
}
.LinkExchange_form_group{
  text-align: center;
    margin-top: 12px;
} */


.LinkExchange_modal_text_heading{
  text-align: center;
 
  margin: 5px 0px;
}
.LinkExchange_modal_text{
  text-align: center;
  font-family: monospace;
  padding: 24px 0px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: inline-block;
  width: 48%;
  margin: 1%;
  background: var(--background-secondary);
 
}
.LinkExchange_modal_text_deduct{
  /* text-align: center; */
  /* color: blue; */
  font-family: monospace;
  display: flex;
  justify-content: center;
  margin: 10px 0px;
}
.LinkExchange_form_group{
  text-align: center;
    margin-top: 2%;
    margin-bottom: 1%;
    padding: 2px 0px;
}


.LinkExchange_modal_number{
  padding: 0px 6px;
  /* color: #6400ff; */
  /* background: linear-gradient(to right, #6400ff, #dd70dd); */
  /* background: linear-gradient(to right, #6400ff, #f10588) */
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
}

.LinkExchange_modal_deduct_number{
  padding: 0px 6px;
  color: #ff0026;
  font-weight: bold
  
  
  
}

.apify_link_exchange{
  margin-top: 34px;
}
textarea{
  max-height: 650px;
}
/******************************************************************************************/

.scraping_progress_bar_container{
  width: 40%;
  margin-left: 2%;
}
.scraping_progress_bar{
  width: 100%;
  position: relative;
}
.scraping_progress_bar_footer{
  margin: 3% 0 2%;

}



/******************************************************************************************/
