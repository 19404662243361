

.dashboard-header {
  width: 100%;
  /* background-color: var(--tableBody-temp); */
  padding: 20px;
  margin: 30px 0;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dashboard-header_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#billing-header {
  font-size: 24px;
  /* color: #333; */
}

.add-credit-points {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #007bff; /* blue color */
}

.add-credit-points span {
  margin-left: 10px;
}

.add-credit-points span:hover {
  text-decoration: underline;
}
 
.credit_price_details_container{
  display: flex ;
  justify-content: space-around;
}
.credit-details-container {

  width: 75%;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.credit-record {
  background-color: var(--tableBody-temp);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  flex: 1;
  margin-right: 20px;
}
.price_record{
  padding: 0px 0 18px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.price-record{
  background-color: var(--tableBody-temp);
  padding: 64px 0;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  flex: 1;
  margin-right: 20px;
  height: 100%;
}
.credit-record:hover {
  transform: translateY(-5px);
}

.record-heading {
  font-weight: bold;
  font-size: 18px;
  /* color: #333; */
}

.record-value {
  font-size: 24px;
  color: #007bff;
}

.credit-details-table-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.table-container {
  width: 49%;
  background-color: var(--tableBody-temp);
}

.chart-container {
  width: 49%;
  background-color: var(--tableBody-temp);
}
.calendar-container{
  display: flex;
  align-items: center;
}
.chart-details {
  display: flex;
  justify-content: space-between;
}

.chart-container-details {
  width: 60%;
}

.credit-details-list {
  width: 35%;
}

.credit-details-list ul {
  list-style-type: none;
  padding: 0;
}

.credit-details-list li {
  margin-bottom: 10px;
}

.credit-details-list .record-heading {
  font-weight: bold;
  font-size: 16px;
  color: #333;
}

.credit-details-list .record-value {
  font-size: 18px;
  color: #007bff;
}
