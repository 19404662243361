.finalize_opurtunity_container{
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 30px;
}

.finalize_opurtunity_container .header_and_go_back_button_container{
    display: flex;
    gap: 15px;
    align-items: center;
  }

  .finalize_opurtunity_container .header_and_go_back_button_container .go_back_button{
    background: var(--background-prev);
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    cursor: pointer;
  }
  .finalize_opurtunity_container .header_and_go_back_button_container .go_back_button svg{
    color: var(--text-tertiary);
  }


.finalize_opurtunity_container .header_container{
    display: flex;
    flex-direction: column;
    gap: 5px;
    /* margin: 1% 0; */
    text-align: left;
  }
  .finalize_opurtunity_container .header_container .header {
    font-size: var(--heading-font-size);
  }
  
  .finalize_opurtunity_container .header_container .sub_header {
    font-size: var(--text-font-size);
  }
  .finalize_opurtunity_container .dashboard_container {
    display: flex;
    align-items: center;
    border-radius: 8px;
    gap: 5px;
  }
  
  .finalize_opurtunity_container .dashboard_data_container {
    background-color: var(--tableBody-temp);
    /* margin: 10px; */
    border-radius: 10px;
    width: 25%;
    height: 150px;
    display: flex;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .finalize_opurtunity_container .dashboard_data_container .yes_no_count_container{
    display: flex;
    gap: 10px;
    width: 86%;
    height: 70%;
    justify-content: space-between;
    align-items: flex-end;
  }
  .finalize_opurtunity_container .dashboard_data_container .yes_no_count_container .yes_no_count_number_container{
    display: flex;
    flex-direction: column;
    width: 50px;
    align-items: center;
    border-bottom: 1px solid;
    border-radius: 5px;
    padding: 7px 7px 0px 4px;
    line-height: 1;
    cursor: pointer;
  }
  
  
  
  .finalize_opurtunity_container .section_label {
    font-size: 14px;
    color: #b0b0b0;
  }
  
  .finalize_opurtunity_container .section_count {
    font-size: 24px;
    font-weight: bold;
  }

  .finalize_opurtunity_container .oppurtunity_section_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 35px 25px 10px 25px;

  }

  
  .finalize_opurtunity_container .oppurtunity_section_label {
    font-size: 14px;
    color: var(--text-secondary);
  }
  
  .finalize_opurtunity_container .oppurtunity_section_count {
    font-size: 55px;
    
    width: 16%;
    
    color: var(--text-primary);
    cursor: pointer;

  }
  .finalize_opurtunity_container .dashboard_data_container .yes_no_count_container .yes_no_count_number_container .yes_no_count_number_size{
    font-size: 40px;
    
  }
  .finalize_opurtunity_container .dashboard_data_container .yes_no_count_container .yes_no_count_details_container{
    display: flex;
    gap: 6px;
    cursor: pointer;
    border-bottom: 1px solid;
    border-radius: 5px;
    padding-bottom: 2px; 
    line-height: 1;   
  }

  
  
  .finalize_opurtunity_container  .yes_count {
    color: #66bb6a;
  }
  
  .finalize_opurtunity_container  .no_count {
    color: #ef5350;
  }
  
  .finalize_opurtunity_container .section_details {
    font-size: var(--text-font-size);
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 10px;
    margin: 0 0 8px 0px;
    color: var(--text-secondary);
  }
  
  .finalize_opurtunity_container .select_section .select_box {
    background-color: var(--background-body);
    color: var(--text-primary);
    font-size: var(--text-font-size);
    border-radius: 7px;
  }
  
  .finalize_opurtunity_container .select_box .MuiSelect-select {
    padding: 6px 10px;
  }
  

  .finalize_opurtunity_container .dashboard_data_container .newly_confirm_section {
    background-color: var(--tableBody-temp);
    
    border-radius: 10px;
    width: 723px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
  }

  .finalize_opurtunity_container .dashboard_data_container  .filter_active{
    background-color: #b0b0b01f;
  }
  .oppurtunity_section_container_with_filter{
    width: 115px;
    line-height: 1;
    border-bottom: 1px solid;
    border-radius: 5px;
    padding: 10px 7px 7px 7px;
  }
  .finalize_opurtunity_container .dashboard_data_container .newly_confirm_section .newly_confirm_section_count {
    font-size: 40px;
    border-bottom: 1px solid;
    border-radius: 5px;
    padding-bottom: 2px;
    line-height: 1;
    width: 35px;
    padding: 0 0 0 4px;
  }

  .finalize_opurtunity_container .dashboard_data_container .newly_confirm_section .newly_confirm_section_label {
    font-size: var(--text-font-size);
  }

  .finalize_opurtunity_container .dashboard_data_container .newly_confirm_with_select_section{
    width: 100%;
    display: flex;
    margin: 25px 0 0px 0;
    justify-content: space-around;
    padding: 0 20px;
    align-items: center;
  }

  .finalize_opurtunity_container .dashboard_data_container .newly_confirm_with_select_section .select_section{
    padding: 0 0 44px;
  }
  

  .finalize_opurtunity_container .open_link_container{
    height: 75%;
    width: 75%;
    display: flex;
    text-align: right;
    align-items: center;
    justify-content: right;
  }

  .finalize_opurtunity_container  .open_link_container .open_link_button{
    font-size: 30px;
    cursor: pointer;
    padding: 10px 9px 13px 13px;
    background: var(--background-prev);
    border-radius: 50%;
  }

  .custom_button_container_in_finalize_table{
    display: flex;
    gap: 10px;
        padding: 5px 0;
  }
  .bulk-edit-container {
    display: flex;
    align-items: flex-end;
    gap: 20px; /* Adjusted gap for spacing */
  }
  
  .form_heading {
    color: var(--text-primary);
    font-size: var(--text-font-size);
  }
  
  .custom-select-container {
    position: relative;
    width: 350px; /* Adjusted width to match the image */
  }
  
  .custom-select {
    width: 100%;
    height: 40px;
    background-color: var(--background-default);
    color: var(--text-primary);
    border: 1px solid var(--tableBorder-border);
    border-radius: 5px;
    padding: 0 10px;
    font-size: var(--text-font-size);
    appearance: none;
    cursor: pointer;
  }
  
  .custom-select::after {
    content: '';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border: 6px solid transparent; /* Arrow size */
    border-top-color: var(--text-primary); /* Arrow color */
  }
  
  .bulk-edit-apply {
    background-color: #3a78f2;
    color: #fff;
    border: none;
    padding: 10px 20px; /* Button padding */
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .bulk-edit-apply:hover {
    background-color: #3366cc;
  }
  
  .finalize_page_bulk_action_container{
    justify-content: space-between;
    display: flex;
    align-items: flex-end;
    margin: 0 0 7px;
    gap: 100px;
  }

  .settings_button_container {
    margin-left: auto;
}

.settings_button {
    display: flex;
    align-items: center;
    background-color:  var(--background-body);
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    color: var(--logo-text);
    border: 1px solid var(--border-primary);
}


.settings_icon {
    margin-right: 8px;
    font-size: 20px;
}
.tooltip-text {
  color: var(--text-primary); /* Customize your text color here */
}

 
.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: var(--background-default);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.modal-title {
  font-size: var(--heading-font-size);
  font-weight: bold;
  color: var(--text-primary);
  /* margin-bottom: 10px; */
}

.modal-description {
  font-size: var(--text-font-size);
  color: var(--text-primary);
  margin-bottom: 20px;
}

.modal-button {
  font-size: var(--text-font-size);
  font-weight: 500;
  background-color: #007bff;
  color: var(--text-secondary);
  text-transform: none;
  /* padding: 10px 20px; */
  border-radius: 5px;
}

.modal-button:hover {
  background-color: #0056b3;
}
