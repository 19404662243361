
.Client_Urls_Bulk_Process_Section
{
  width: 100%;
}
.bulk_process_container{
  margin: 2% 0;
}
.Client_Urls_Section
{
    width: 100%;
    /* height: 332px; */
    overflow: auto;
    height: 70vh;
}

/**/

.Client_Urls_Action_Section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: var(--tableHeader-temp);
  border: 1px solid var(--tableBorder-border);
  margin-top: 10px;
  border-radius: 10px;
}

.Client_Url_Select_All {
  margin-right: 10px;
}
.Client_Url_Select_All input[type="checkbox"]
{
  height: 15px;
}
.Process_Button_Container {
  /*flex-grow: 1;
  margin-right: 10px;*/
  /* margin-left: auto;
  margin-right: 30px; */
  font-size: var(--text-font-size);
}
.Process_Button_Container_text{
  font-size: var(--text-font-size);
  display: flex;
  align-items: center;
  gap: 30px;
}
.Download_Button_Container
{
  margin-right: 25px;
}
/*
 style={{marginTop: "10px", marginLeft: "10px"}}
*/

.Process_Selected_Clients {
  margin-bottom: 10px;
}

.Client_Url_Row {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: var(--tableBody-temp);
  border: 1px solid var(--tableBorder-border);
  border-radius: 10px;
  margin-top: 5px;
  font-size: var(--text-font-size);
}

.Client_Url_Select {
  margin-right: 10px;
}

.Client_Url {
  flex-grow: 1;
  width: 65%;
  margin-right: 25px;
  font-size: var(--text-font-size);
}

.Client_Url_Progress {
  flex-basis: 60%;
  margin-left: 10px;
}
.process_scrape_save_download_button_container{
  display: flex;
  gap: 10px;
  align-items: center;
}

.bulk_process_save_modal_container{
  display: flex;
  flex-direction: column;
  gap: 17px;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
}

.bulk_process_save_modal_header{
  font-size: var(--heading-font-size);;

}
.bulk_process_save_modal_sub_header{
  font-size: var(--text-font-size);
}


.bulk_process_save_modal_actions{
  display: flex;
  align-items: center;
  gap: 10px;
}


.bulk_process_heading_container{
  display: flex;
  justify-content: left;
  width: 100%;
}
.bulk_process_heading_text{
  font-size: var(--heading-font-size);
  font-weight: 900;
}

.bulk_process_sub_heading_container{
  display: flex;
  justify-content: left;
  width: 100%;
}
.bulk_process_sub_heading_text{
  font-size: var(--text-font-size);
}

.bulk_process_heading_and_sub_heading_container{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 2% 0;
}
.bulk_process_sub_heading_text {
  font-size: var(--text-font-size);
  line-height: 1.6;
  text-align: left;
}



