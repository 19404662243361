.status_model_box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent overlay */
    backdrop-filter: blur(4px); /* Optional blur for a frosted glass effect */
}

.status_modal_container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    background-color: var(--background-default);;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    width: 300px;
    text-align: center;
}
.status_modal_container .header {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: var(--heading-font-size);
    font-weight: bold;
    color: var(--logo-text);
    text-transform: uppercase;
}

.status_modal_container .main_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    font-size: var(--text-font-size);
    color: var(--logo-text);
}

.status_modal_container .main_content div {
    font-size: var(--text-font-size);
}

.status_modal_container.success {
    border: 2px solid #4CAF50;
    background-color: var(--background-default);;
}

.status_modal_container.loading {
    border: 2px solid #FFA500;
    background-color: var(--background-default);;
}

/* .icon-sticker {
    font-size: 1.5em;
} */
.icon-gif {
    width: 24px;
    height: 24px;
    margin-left: 8px;
}