.selected_client_url,
.selected_blog_url,
.link_insertion_prompt .MuiTypography-root,
.link_insertion_suggestion .MuiTypography-root,
.link_matching_check_header
{
  /* color: #194f00; */
  font-weight: bold;
}
.link_insertion_container{
  margin-top: 20px;
}
.link_matching_check_header
{
  padding: 5px 10px 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.column_header
{
  width: 22%;
  text-align: center;
}


.link_matching_check_container {
  height: 400px;
  padding: 5px 10px 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* color: #fff; */
}

.link_matching_check_container > .extreme_left,
.link_matching_check_container > .left,
.link_matching_check_container > .right,
.link_matching_check_container > .extreme_right {
  overflow: auto;
  height: calc( 100% - 20px );
  width: 22%;
  padding: 1%;
  /* background: #234; */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.link_matching_check_container > .devider {
  height: calc( 100% - 20px );
  width: 15px;
  /*background: linear-gradient(135deg, #64ed7b, #66ed88, #64ed7b)
  background: linear-gradient(135deg, #5f5bff, #00eaff, #5f5bff)
  background: linear-gradient(135deg, #000000, #00a8a0, #000000)
  background: linear-gradient(135deg, #000000, #787878, #000000)*/
  /* background: #101F33; */
}
.extreme_left {
  /*background: linear-gradient(135deg, #c0ff5b, #00eaff);
  background: linear-gradient(135deg, #5f5bff, #00eaff);
  background: linear-gradient(135deg, #000000, #00a8a0);
  background: linear-gradient(135deg, #000000, #787878);*/
}

.left {
  /*background: linear-gradient(135deg, #00eaff, #64ed7b);
  background: linear-gradient(135deg, #00eaff, #5f5bff);
  background: linear-gradient(135deg, #00a8a0, #000000);
  background: linear-gradient(135deg, #787878, #000000);*/
  margin-right: 10px;
}

.right {
  /*background: linear-gradient(135deg, #64ed7b, #00eaff);
  background: linear-gradient(135deg, #5f5bff, #00eaff);
  background: linear-gradient(135deg, #000000, #00a8a0);
  background: linear-gradient(135deg, #000000, #787878);*/
  margin-left: 10px;
}

.extreme_right {
  /*background: linear-gradient(135deg, #00eaff, #c0ff5b);
  background: linear-gradient(135deg, #00eaff, #5f5bff);
  background: linear-gradient(135deg, #00a8a0, #000000);
  background: linear-gradient(135deg, #787878, #000000);*/
}
/******************************************************************************************************/
.link_container.link_container_selected
{
  border: 1px solid #bbb;
  box-shadow: -3px 2px 10px 1px #999;
  transform: scale(1.05);
}

.link_container {
  /* background-color: #ffffff3b; */
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: block;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
  word-break: break-all;
}

.link_container:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.25);
  transform: scaleX(0);
  transition: transform 0.3s ease;
  z-index: -1;
}

.link_container:hover {
  transform: scale(1.05);
}

.link_container:hover:before {
  transform: scaleX(1);
}

.link_container:first-child {
  margin-top: 10px;
}

.link_container:last-child {
  margin-bottom: 0;
}

.link_container svg
{
  cursor: pointer;
  margin: 0px 0px -4px 0px;
}
.link_container svg.serach
{
  float: right;
}
/* *********************************************** link_insertion_suggestion_section start *********************************************** */



.link_insertion_suggestion_section
{
  margin: 20px 26px 30px 20px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #b6b6b6;
  box-shadow: 5px 5px 12px 0px #adadad;

}



.selected_client_url,
.selected_blog_url {
  margin-bottom: 20px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
}

.link_insertion_generated {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.link_insertion_prompt,
.link_insertion_suggestion {
  flex: 1;
  margin-right: 20px;
  margin-bottom: 10px;
}

.link_insertion_prompt textarea,
.link_insertion_suggestion textarea {
  padding: 12px;
}

.link_insertion_prompt textarea:focus,
.link_insertion_suggestion textarea:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(95, 158, 160, 0.4);
}



/* *********************************************** link_insertion_suggestion_section end *********************************************** */

/*                                 scroll bar section start                                 */


/* Hide the default scroll bar for all elements */
*::-webkit-scrollbar {
  width: 4px;
  height: 5px;
  background-color: #E0E0E0; /* Light background for contrast */
}

*::-webkit-scrollbar-thumb {
  background-color: #494949; /* Dark gray color */
  border-radius: 0.5em; /* Smoother rounding for a polished look */
  transition: background-color 0.3s ease, width 0.3s ease, height 0.3s ease; /* Smooth transition */
}

*::-webkit-scrollbar:hover {
  width: 8px; /* Increased width on hover */
  height: 6px; /* Increased height on hover */
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #363636; /* Darker gray color on hover */
}

/*                                 scroll bar section end                                 */
