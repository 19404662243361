/* ContactUs.css */

/* Styling for the contact us section container */
#contactUs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* min-height: 100vh; */
  width: 100%;
  /* padding: 3rem; */
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  /* margin-top: 100px; */
  margin-bottom: 21%;
}

/* Keyframes for the fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Styling for the main heading */
#contactHeading {
  /* font-size: var(--heading-font-size); */
 
  font-weight: bolder;
  /* margin-bottom: 2rem; */
  @media (max-width: 442px) {
    font-size: var(--text-font-size);
  }
}

/* Styling for the form container and company information */
#formContainer
 {
  display: flex;
  flex-direction: column;
  margin: 2rem auto;
  /* max-width: 100vh; */
  width: 100%;
  /* height: 60vh; */
  padding: 2rem;
  border-radius: 10px;
  /* box-shadow: 0 0 10px rgba(196, 157, 157, 0.2); */
  background: var(--background-secondary);
}
#companyInfo{
  padding: 1rem;
  margin: 0.3rem auto;
  width: 40%;
}


/* Styling for the text fields */
.contactTextField {
  margin-bottom: 1.5rem;
  font-weight: 500;
  font-size: var(--text-font-size);
  border: 2px solid #d0d0d0;
  border-radius: 5px;
  padding: 0.8rem;
  transition: border-color 0.3s, box-shadow 0.3s;
  width: 100%;
}

/* Hover effect for text fields */
.contactTextField:hover {
  border-color: #1a73e8; /* Google Blue color */
  /* box-shadow: 0 0 10px rgba(26, 115, 232, 0.5); */
}

/* Styling for InputProps */
.contactInputProps {
  font-weight: bold;
}

/* Styling for InputLabelProps */
.contactInputLabelProps {
  font-weight: bold;
}

/* Styling for the submit button with hover effect */
#submitButton {
  margin-top: 1.1rem;
  /* background-color: #1a73e8; 
  color: #fff;
  font-weight: bold;
  border: none;
  padding: 1rem 2rem; */
  border-radius: 5px;
  padding: 1%;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s, box-shadow 0.3s;
  width: 16%;
}

/* Hover effect for the submit button */
#submitButton:hover {
  /* transform: scale(1.1); */
  background-color: #1558c3; /* Darker blue on hover */
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); */
}

/* Styling for the map container */
#mapContainer {
  width: 100%;
  height: 400px;
  margin-top: 3rem;
  border-radius: 10px;
  overflow: hidden;
  /* box-shadow: 0 0 30px rgba(196, 157, 157, 0.2); */
}

/* Additional styling for iframe inside map container */
#mapContainer iframe {
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 10px;
  transition: transform 0.3s;
}

/* Hover effect for the map on desktop */
/* @media (min-width: 768px) {
  #mapContainer:hover iframe {
    transform: scale(1.05);
  }
} */

/* Styling for the company information content */
/* #companyInfo {
  margin: 2rem auto;
  max-width: 800px;
  width: 100%;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
} */

/* Hover effect for the company information */
#companyInfo:hover {
  /* transform: scale(1.05); */
  /* box-shadow: 0 0 30px rgba(196, 157, 157, 0.2); */
}
#formContainer:hover {
  /* transform: scale(1.05); */
  /* box-shadow: 0 0 30px rgba(196, 157, 157, 0.2); */
}





/* Styling for the company description */
#companyDescription {
  font-size: 1rem;
}

/* Styling for the contact details container */
.contactDetailsContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 1.5rem;
}

/* Styling for the contact details */
.contactDetail {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1rem;
}

/* Custom styling for icons */
.contactIcon {
  margin: 1rem;
  color: #5b5d60; /* Google Blue color */
  font-size: 1.5rem;
}

/* Styling for clickable icons */
.contactIcon a {
  color: inherit; /* Inherit the color from the parent */
  text-decoration: none;
}
.checkbox_section{
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-left: -14px;
}


.contactInfo{
  background: var(--background-secondary);
  border-radius: 17px;
  margin: 8px 0px;
  text-align: left;
  /* box-shadow: 0 0 30px rgba(196, 157, 157, 0.2); */
}

.contactInfo  a{
color: var(--icon-text);
text-decoration:none
}

.contact_container{
  width: 100%;
  display: flex;
  margin-left: 1%;
}
.contact_container_form{
  width: 60%;
}