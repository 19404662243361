.headerSection {
    height: 56px;
    width: 100%;
    position: relative;
    /* background-color: var(--header-secondary); */
    background-color: transparent;
}
/* .transparentBackground {
  background-color: transparent !important; 
} */

.rightSection {
  text-align: right;
  top: 0;
  height: 56px;
  margin-right: 60px;
}

.customBoxStyles, .loginRegisterButtons {
  margin-right: 16px;
}






/* .avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
} */
.loginRegisterButtons{
  /* width: 100%; */
  text-align: right;
  justify-content: right;
  padding-top: 10px;
}