/* AdminPage.css */
.admin_page_heading_{
  text-align: left;
  display: flex;
  width: 100%;
  margin-left: 4%;
  font-size: var(--heading-font-size);
}
.admin-page {
  display: flex;
  height: 100%;
}

.admin-content {
  flex: 1;
  padding: 20px;
}

.dashboard {
  /* display: flex; */
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  width: 100%;
}
.separator{
  display: flex;
  justify-content: space-between;
}
.section {
  background-color: var(--tableBody-temp  );
  margin: 10px;
  border-radius: 10px;
  width: 33%;
  height: 62vh;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.section ul {
  list-style: none;
  padding: 0;
}

.section li {
  margin-bottom: 5px;
}


@media screen and (max-width: 768px) {
  .dashboard {
    grid-template-columns: 1fr;
  }
}
.calendar-section {
  /* Add background color and border color for the calendar */
  background-color: var(--container-admin);
  border: 1px solid var(--tableBorder-border);
  color: var(--logo-text);
}

.task-cards {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
  gap: 5px;
}

.task-card {
  background-color: var(--container-admin  );
  border: 1px solid var(--tableBorder-border);
  border-radius: 8px;
  /* padding: 20px; */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.task-card:hover {
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
}

.task-card h3 {
  margin-top: 0;
}

.task-card p {
  margin-bottom: 10px;
}

.task-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}

.task-details p {
  margin: 0;
}

.status-indicator {
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 20px;
  text-transform: uppercase;
  height: 24px;
  display: flex;
  align-items: center;
}

.status-indicator.completed {
  background-color: #4caf50;
}

.status-indicator.pending {
  background-color: #ff9800;
}


/*  recent activity */
.activity-section {
  /* background-color: #f8f9fa; */
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section-title {
  /* color: #333; */
  font-size: 24px;
  margin-bottom: 20px;
}

.activity-table {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--tableBorder-border); /* Add border around the table */
}

.activity-row {
  display: flex;
}

.activity-column {
  flex: 1;
  padding: 10px;
  border-bottom: 1px solid var(--tableBorder-border); /* Add separator between rows */
}

.activity-column:last-child {
  flex: 0.5; /* Adjust width of the time column */
}

.activity-row.header {
  font-weight: bold;
  
}

.latest-reviews-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.review-card {
  /* background-color: #fff; */
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.review-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reviewer-name {
  font-weight: bold;
}

.review-date {
  /* color: #888; */
  font-size: 14px;
  margin-left: 10px;
}

.review-content {
  margin-top: 10px;
}
