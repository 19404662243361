/* RegisterPage.css */

.register_container {
  max-width: 650px; 
  margin: 10px auto 10px;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.1);
  height: fit-content;
  background: var(--container-body);
}
.login_container{
  max-width: 500px; 
  margin: 30px auto;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.1);
  background: var(--container-body);
}

.form-container {
  display: grid;
  grid-gap: 10px;
}

.center-align {
  text-align: center;
  padding-bottom: 5px;
}

.label {
  font-size: 18px;
}

.input {
  width: 100%;
  padding: 10px 0; /* Adjusted padding */
  font-size: var(--text-font-size);
  border: none;
  border-bottom: 1px solid ; /* Only border bottom */
  background-color: transparent; /* Same color as background */
  transition: border-color 0.3s ease;
  color: var(--text-primary);
}
.container_header{
  font-size: var(--heading-font-size);
  color:#007bff ;
  font-weight: bold;
  padding: 16px 0;
}
.container_text{
  font-size: var(--text-font-size);
  padding: 0 10px;
}

.input:focus {
  outline: none;
  border-color: #007bff; /* Focus color */
}

.termsAndConditionBox {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.checkboxInput {
  margin-right: 10px;
}

.error_message {
  color: red;
  font-size: 14px;
}

.button-container {
  text-align: center;
  margin-top: 10px;
}

.button {
  padding: 12px 24px;
  font-size: var(--text-font-size);
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}

.link-container {
  text-align: center;
  margin-top: 20px;
}

.link-container a {
  color: #007bff;
  text-decoration: none;
}

.link-container a:hover {
  text-decoration: underline;
}

/* RegistrationSuccessPage.css */

.registration-success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.registration-success-content {
  text-align: center;
}

.registration-success-header {
  font-size: var(--heading-font-size);
  margin-bottom: 20px;
}

.registration-success-text {
  font-size: var(--text-font-size);
}

.button-container {
  display: flex;
  justify-content: center;
}

.login-button,
.home-button {
  display: inline-block;
  padding: 12px 24px;
  margin: 0 10px;
  background-color: #0561ff; /* Green color */
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s;
}
.icons{
  font-size: var(--icon-font-size);
}