.render_campaign_container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 0 12px 0 #0c0c0c36;
}
.render_campaign_container {
  border-radius: 10px;
  box-shadow: 0 0 12px 0 #0c0c0c36;
}
.campaign_links_container {
  border-radius: 10px;
  box-shadow: 0 0 12px 0 #0c0c0c36;
  background-color: var(--background-primary);
  width: 33%;
}
.credit_records_container_body {
  border-radius: 10px;
  box-shadow: 0 0 12px 0 #0c0c0c36;
}

.homepage .add_campaign_container {
  display: flex;
    align-items: center;
    gap: 5px;
    margin:5px 0 3px 55px;
    font-size: var(--text-font-size);
}

.homepage .add_campaign_container:hover {
  text-decoration: underline;
  cursor: pointer;
}
.home_page_primary_container {
  width: 98%;
  margin: 0% 2% 2% 2%;
  display: flex;
  gap: 30px;
}
.home_page_secondary_container {
  width: 98%;
  margin: 2%;
  display: flex;
  gap: 30px;
}
.recently_used_campaign_container {
  width: 30%;
  text-align: left;
}

.campaign_table {
  width: 100%;
  border-collapse: collapse;
}

.campaign_table_header {
  display: flex;
  background-color: var(--background-primary);
  padding: 16px 18px 16px 26px;
  border: 1px solid var(--tableBorder-border2);
  font-size: 17px;
  font-weight: 600;
  height: 60px;
  border-radius: 10px 10px 0 0;
}

.campaign_table_header_item {
  flex: 1;
}

.campaign_table_header_item .header_container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.campaign_table_body {
  width: 100%;
  height: 453px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--background-secondary);
  border-radius: 0 0 10px 10px;
  margin: 2px 0;
  padding: 0 20px 10px;
}

.campaign_table_row {
  display: flex;
  cursor: pointer;
  padding: 10px;
  height: 73px;
}

.campaign_table_row:hover {
  background-color: var(--tableBody-hover);
}

.campaign_table_cell {
  /* flex: 1 1; */
  display: flex;
  gap: 25px;
  align-items: center;
  cursor: pointer;
  width: 100%;
  font-size: 17px;
}

.campaign_link {
  color: inherit; /* Ensures link inherits text color */
  text-decoration: none; /* Removes default underline */
  cursor: pointer; /* Changes cursor to pointer on hover */
  display: flex;
  gap: 25px;
  align-items: center;
  cursor: pointer;
  width: 100%;
  font-size: 17px;
}
.campaigns_link {
  color: inherit; /* Ensures link inherits text color */
  text-decoration: none; /* Removes default underline */
  cursor: pointer; /* Changes cursor to pointer on hover */
  display: flex;
  gap: 25px;
  align-items: center;
  cursor: pointer;
  width: 100%;
  font-size: 17px;
}
.campaign_table_cell_icon {
  font-size: 30px;
}
.campaign_table_row.highlight {
  background-color: var(--tableBody-hover);
}

.campaign_link:hover {
  text-decoration: underline; /* Underline link on hover */
}
.campaign_link_texts:hover {
  text-decoration: underline; /* Remove underline on hover */
}

.campaign_links_item:hover {
  background-color: var(--tableBody-hover);
}

.campaign_link_text {
  display: flex;
  gap: 25px;
}
.campaign_link_text {
  text-decoration: none;
}
.more_less_button_container {
  display: flex;
  justify-content: center;
  background-color: var(--background-secondary);
  height: 37px;
  border-radius: 0 0 10px 10px;
  margin-top: 10px;

}

/* HomePage.css */

.credit_records_container {
  width: 33%;
  padding: 0 32px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.credit_records_header {
  background-color: var(--background-primary);
  padding: 16px 0px 16px 19px;
  border: 1px solid var(--tableBorder-border2);
  font-size: 17px;
  font-weight: 600;
  height: 60px;
  border-radius: 10px 10px 0 0;
  text-align: left;
}

.credit_records_header_item {
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  display: flex;
}

.credit_records_body {
  width: 100%;
  height: 210px;
  background-color: var(--background-secondary);
  border-radius: 0 0 10px 10px;
  margin: 2px 0;
  padding: 10px;
}

/* .credit_record_box {
  margin-bottom: 10px;

  border-radius: 5px;
  padding: 0 10px;
} */

.credit_record_details {
  font-size: 16px;
}
.credit_record_heading {
  display: flex;
  gap: 14px;
  align-items: center;
}
.credit_record_heading_sticker {
  font-size: 20 px;
}
.credit_record_heading_text {
  display: flex;
  text-align: left;
  font-size: 17px;
  align-items: center;
  gap: 5px;
}

.credit_record_heading_text .in_charge_container{
  display: flex;
  align-items: center;
  gap: 5px;
}
.credit_record_heading_text .in_charge_container:hover{
  cursor: pointer;
}
.credit_record_field {
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  height: 42px;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  font-size: 17px;
}

.credit_record_value {
  font-size: 18px;
  text-align: right;
}
.credit_records_header_item_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 92%;
}
.credit_records_header_item_icon {
  width: 7%;
}


.campaign_links_header {
  display: flex;
  background-color: var(--background-primary);
  padding: 16px 16px 16px 19px;
  height: 60px;
  border: 1px solid var(--tableBorder-border2);
  font-size: 17px;
  font-weight: 600;
  border-radius: 10px 10px 0 0;
}

.campaign_links_header_item {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.campaign_links_header_item_text {
  width: 93%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.campaign_links_header_item_icon {
  width: 7%;
  text-align: right;
}
.campaign_links_body {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  height: 500px;
  background-color: var(--background-secondary);
  border-radius: 0 0 10px 10px;
  margin: 2px 0;
  padding: 10px 20px;
}

.campaign_links_item {
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: left;
  height: 74px;
}
/* WelcomeSection.css */
.welcome_section {
  width: 48%;
}

.welcome_header {
  display: flex;
  background-color: var(--background-primary);
  padding: 16px 43px;
  border: 1px solid var(--tableBorder-border2);
  font-size: 17px;
  font-weight: 600;
  border-radius: 10px 10px 0 0;
}

.welcome_content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  height: 450px;
  background-color: var(--background-secondary);
  border-radius: 0 0 10px 10px;
  margin: 2px 0;
  padding: 10px 20px;
}

.welcome_item {
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: left;
  height: 74px;
}

.welcome_icon {
  font-size: 30px;
}

.welcome_text {
  display: flex;
  flex-direction: column;
}

.welcome_title {
  font-weight: bold;
}
.campaign_table_cell_text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
}

.welcome_description {
}
/* HomePage.css */

.video_play_section {
  width: 48%;
}

.video_play_header {
  display: flex;
  background-color: var(--background-primary);
  padding: 16px 43px;
  border: 1px solid var(--tableBorder-border2);
  font-size: 17px;
  font-weight: 600;
  border-radius: 10px 10px 0 0;
}

.video_play_content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  height: 450px; /* Adjust height as necessary */
  background-color: var(--background-secondary);
  border-radius: 0 0 10px 10px;
  margin: 2px 0;
  padding: 10px 20px;
}

.video_player {
  width: 100%;
  height: 100%; /* Ensure the video fills its container */
  border-radius: 10px;
  outline: none; /* Remove focus outline */
}

/* Ensure video controls are visible and styled appropriately */
.video_player::-webkit-media-controls {
  display: flex;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity and color as needed */
}

.video_player::-webkit-media-controls-play-button {
  color: white; /* Control button color */
}

.video_player::-webkit-media-controls-volume-slider {
  width: 80px; /* Adjust volume slider width */
}

.video_player::-webkit-media-controls-mute-button {
  color: white; /* Mute button color */
}

.video_player::-webkit-media-controls-timeline {
  background-color: rgba(255, 255, 255, 0.3); /* Adjust timeline background */
}

.video_player::-webkit-media-controls-current-time-display,
.video_player::-webkit-media-controls-time-remaining-display {
  color: white; /* Adjust time display color */
}

.video_player::-webkit-media-controls-fullscreen-button {
  color: white; /* Fullscreen button color */
}

/* Optionally style other parts of the video controls as needed */
