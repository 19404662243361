
  .yes_confirmed {
    background-color: #4b91ca;
    color: var(--button-select_text);
    cursor: not-allowed;
    border: 2px solid #4b91ca;

  }
  
   .no_confirmed {
    background-color: #d9534f;
    color: var(--button-select_text);
    border: 2px solid #d9534f;

    cursor: not-allowed;
  }
