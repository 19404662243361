svg.OpenInNewIcon {
  cursor: pointer;
}
.ticket_container_box {
  /* display: flex;
    justify-content: center; 
    align-items: center;  */
  height: fit-content;
  max-width: 500px;
  margin: 90px auto;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.1);
  background-color: var(--container-body);
}
.ticket_container_title {
  font-size: var(--heading-font-size); 
  color: #007bff;
  font-weight: bold;
  padding: 16px 0;
}
.ticket_container {
  padding: 20px; /* Example padding */
  border-radius: 10px; /* Example border radius */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Example box shadow */
  max-width: 600px; /* Example maximum width */
  width: 100%;
}
