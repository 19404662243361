.rightSection_withOutMenu {
    width: 100%;
    height: 60px;
  }
  
  .header_buttons {
    /* Add your header buttons styles here if needed */
  }
  
  .login_page {
    margin-top: 30px;
  }
  
  
  
  .login-paper {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 320px;
    width: 100%;
  }
  
  .login-icon {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .login-input-container {
    margin-bottom: 20px;
  }
  
  .login-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .login-error {
    color: #e74c3c;
    margin-top: 10px;
  }
  
  .login-btn {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #0a97f5;
    color: #fff;
    font-size: 16px;
    transition: background 0.3s ease-in-out;
  }
  
  .login-btn:hover {
    background: #5f95b9;
  }
  
  .login-signup {
    margin-top: 20px;
  }
  
  .login-signup-btn {
    text-decoration: none;
    padding: 7px;
    border-radius: 4px;
    color: #007bff;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .login-signup-btn:hover {
    background: #5f95b9;
    color: #fff;
  }
  