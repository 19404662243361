
#productSection {
  text-align: center;
  padding: 32px;
  height: fit-content; 
}

#productHeading {
  font-size: var(--heading-font-size);
  margin-top: 24px;
  font-weight: bolder;
  margin-bottom: 32px;
  @media (max-width: 442px) {
    font-size: var(--text-font-size);
  }
}

.imageSlider {
  padding-left: 25%;
}



.slider {
  width: 800px;
  height: 500px;
  border-radius: 10px;
  overflow: hidden;
}

.slides {
  width: 500%;
  height: 500px;
  display: flex;
}

.slides input {
  display: none;
}

.slide {
  width: 20%;
  transition: 2s;
  position: relative;
}

.slide img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.description h2 {
  font-size: 24px;
  margin-bottom: 10px;
}
/* Styling for the product descriptions */
.description {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--background-secondary);
  /* color: #fff; */
  padding: 10px;
  padding-top: 200px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: var(--text-font-size);
}

.navigation_manual {
  position: absolute;
  width: 800px;
  margin-top: 470px;
  display: flex;
  justify-content: center;
}

.manual-btn {
  border: 2px solid black;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  transform: 1s;
}

.manual-btn:not(:last-child) {
  margin-right: 40px;
}

.manual-btn:hover {
  background-color: black;
}

#radio1:checked ~ .first {
  margin-left: 0;
}
#radio2:checked ~ .first {
  margin-left: -20%;
}
#radio3:checked ~ .first {
  margin-left: -40%;
}
#radio4:checked ~ .first {
  margin-left: -60%;
}
#radio5:checked ~ .first {
  margin-left: -80%;
}

.navigation_auto {
  position: absolute;
  display: flex;
  width: 800px;
  justify-content: center;
  margin-top: 470px;
}

.navigation_auto div {
  border: 2px solid black;
  padding: 5px;
  border-radius: 10px;
  transform: 1s;
}

.navigation_auto div:not(:last-child) {
  margin-right: 40px;
}

#radio1:checked ~ .navigation_auto .auto_btn1 {
  background-color: black;
}
#radio2:checked ~ .navigation_auto .auto_btn2 {
  background-color: black;
}
#radio3:checked ~ .navigation_auto .auto_btn3 {
  background-color: black;
}
#radio4:checked ~ .navigation_auto .auto_btn4 {
  background-color: black;
}
#radio5:checked ~ .navigation_auto .auto_btn5 {
  background-color: black;
}
