/* TopButton.css */
.top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: transparent;
    color: #007bff; /* Button color */
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    display: none;
    transition: background-color 0.3s, color 0.3s;
  }
  

  
  .top-button.visible {
    display: block;
  }
  
  /* Additional styles for better appearance */
  body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
  }
  
  .content {
    height: 150vh; /* For testing scroll */
  }
  