@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
:root {
  --heading-font-size: 21px;
  --text-font-size: 14px;
  --icon-font-size: 26px;
  --button-font-size: 12px;
  --table_icon_size: 20px;
}

.team_name {
  text-align: left;
  padding-left: 130px;
  padding-bottom: -32px;
}
.heading_icon {
  font-size: 15px;
}
#campaign_point_deduction_button {
  margin: 5px 30px 4px;
  justify-content: space-between;
  display: flex;
}

#campaign_point_deduction_button_cointainer {
  display: flex;
  justify-content: center;
}
.campaign_details {
  display: flow;
  text-align: center;
}
.credit_points_container {
  text-align: center;
}
.row {
  padding: 15px 0;
}
/* .col{
  padding: 5px 0px;
} */

.my_account_details_heading {
  font-weight: 600;
  font-size: var(--text-font-size);
}
.text-secondary {
  font-size: var(--text-font-size);
}
.img {
  cursor: pointer;
  width: 320px;
  height: 320px;
  border-radius: 50%;
}
.myprofile {
  margin-left: 10px;
}
.card {
  height: 70vh;
  padding: 20px;
}
.my_profile_container {
  padding: 0px 50px;
}
.profile_img {
  padding: 20px;
}
.avater_font {
  font-size: 16px;
}
#avatar_icon_size {
  font-size: 16px;
}
.delete_all_data_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 46px;
}

.delete_all_data_icons {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.icon_text {
  margin-right: 10px;
}
.modal_heading {
  margin-bottom: 1rem;
}

.modal_description {
  margin-bottom: 1rem;
}

.instruction,
.warning,
.important,
.note {
  margin-bottom: 1rem;
}

.instruction strong,
.warning strong,
.important strong,
.note strong {
  display: block;
}

.instruction div,
.warning div,
.important div,
.note div {
  margin-top: 0.5rem;
}

textarea {
  resize: vertical;
  max-height: 400px;
  overflow: auto;
}
div#root {
  height: 100%;
}
div#root > .MuiBox-root {
  height: 100%;
}

.page_container {
}
.page_content_Container {
  /* Don't use margin-top: 55px; */
  height: calc(100% - 55px);
}
.main_content_Container {
  flex-basis: calc(100% - 240px);
  max-width: calc(100% - 240px);
}
.main_content_container_without_leftbar {
  flex-basis: 100%;
  max-width: 100%;
}
.leftBar_Container {
  flex-basis: 240px;
  max-width: 240px;
}

.header_gap {
  height: 55px;
}

.campaign_bodysection {
  margin-right: 30px;
  margin-left: 30px;
}

.active-menu-icon {
  background-color: aqua;
}

.modal_header_container {
  font-size: var(--text-font-size);
  text-align: center;
  margin: 20px auto 0px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 98%;
}

.modal_header_text {
  margin-left: 37%;
}

#accordion_container_for_workspace_team {
  width: 98%;
  border-radius: 10px;
  margin: 3% 1%;
  background-color: var(--background-primary);
}

.member_of_workspace_heading {
  text-align: left;
  margin: 1%;
}
.alert_massage_container {
  margin-left: 1%;
  width: 98%;
}

.breadcrumb_text {
  font-size: var(--text-font-size);
}

#panel1a-header {
  font-size: var(--text-font-size);
}

.close_button_of_modal_container {
  position: absolute;
  top: 12px;
  right: 12px;
}

.next_step_button_container {
  display: flex;
  justify-content: right;
}
#Breadcrumbs {
  margin-left: 32px;

  width: 80%;
  margin: 1% 0px 0px 0%;

  text-decoration: none;
}

/* .add-user-form {
  max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-height: 96vh;
    align-items: center;
}
.modal_header_close_icon{
  position: absolute;
  top: 10px;
  right: 10px;
}
.accordion_content_container{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.modal_content_container{
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 550px;
  overflow: auto;
  width: 100%;
}

.add-user-btn {
  width: 50%;
  padding: 12px;
  background-color: #003aaf;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

}
#accordion_modal {
  margin: 0px 4rem;
  box-shadow: none;
}
.form-group {
  text-align: center;
  align-items: center;
} */

#accordion_modal {
  margin: 0px 4rem;
  box-shadow: none;
  background: var(--background-default);
}

.tab_content_container {
  margin: 1% 0px;
}
