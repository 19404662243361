
.active_bloggers_Container
{
  width: 100%;
  margin: 2% auto;
  text-align: center;
}

.active_bloggers_Title
{
  width: 100%;
  margin: 2% 0;
  font-size: var(--heading-font-size);
  font-weight: 600;
}
.check_active_blogger_text_area_container{
  margin: 2% 0;
  width: 100%;
}

.check_active_blogger_result_number_containr{
  display: flex;
  flex-direction: column;
  margin: 4% 0 2%;
  gap: 18px;

}

.active_bloggers_title_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
}

.active_bloggers_title_text {
  font-size: var(--heading-font-size);
  font-weight: 900;
}

.active_bloggers_subheading_text {
  font-size: var(--text-font-size);
  line-height: 1.6;
  text-align: left;
  color: var(--text-second); /* Optional: Adjust the color for better readability */
}
