/* Footer.css */

footer {
 
  padding: 50px 10px;
  text-align: center;
}

.footerContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footerSection {
  flex: 1;
  max-width: 400px;
  margin-bottom: 20px;
}

/* Styling for the company name */
.footerCompanyName {
  font-weight: bold;
  font-size: var(--heading-font-size);
  margin-bottom: 10px;
  color: #007bff; /* Company name color */
}

/* Styling for the address */
.footerSection div p {
  margin: 0;
}

/* Styling for the contact information and icons */
.footerContact,
.footerIcons {
  margin-top: 20px;
}

.footerContact div,
.footerIcons div {
  margin-bottom: 10px;
}

.footerIcon {
  font-size: var(--heading-font-size);
  transition: color 0.3s ease;
}

.footerIcon:hover {
  color: #007bff; /* Change color on hover */
}

/* Styling for the powered by text */
.footerPoweredBy {
  font-style: italic;
  margin-top: 30px;
  font-size: var(--text-font-size);
}

/* Modern design elements */
.footerSection::after {
  content: "";
  display: block;
  width: 60px;
  height: 3px;
  background-color: #007bff; /* Underline color */
  margin: 10px auto;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .footerContainer {
    flex-direction: column;
    align-items: center;
  }

  .footerSection {
    max-width: none;
    text-align: center;
  }
}
