/* CampaignCard.css */

.campaign_card {
    background-color: var(--background-primary);
    border-radius: 12px;
    padding: 20px;
    width: 320px;
    color: var(--logo-text);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    height: 340px;
    display: flex;
    flex-direction: column;
    /* gap: 15px; */
    justify-content: space-around;
    text-align: left;
}

.campaign_card_title {
    font-size: 18px;
    font-weight: bold;
}

.campaign_card_description {
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.campaign_card_item {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    color: var(--logo-text);
}

.check_mark {
    color: #38c776;
    font-size: 16px;
    margin-right: 8px;
}
.campaign_card_item_text{
    text-align: left;
    font-size: var(--text-font-size);
}
.campaign_card_button {
    background-color: #6a9ffc;
    color: #ffffff;
    border: none;
    padding: 10px 0;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 8px;
    width: 100%;
    text-align: center;
    transition: background-color 0.3s ease;
}

.campaign_card_button:hover {
    background-color: #4a7cd5;
}
