/* body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: #888;
} */
/***************************************************************************************/
.keywordFiltersContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 0px 15px 0px;
  padding: 5px 0px 5px 15px;
  border-radius: 8px;
  border: 1px solid #b6b6b6;
  box-shadow: 5px 5px 12px 0px #adadad;
}

.keywordFilter {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.keywordFilterName {
  font-weight: bold;
  margin-right: 4px;
  color: #107409;
}

.keywordFilterTextField {
  margin-right: 8px;
  text-transform: none;
}

.keywordFilterTextField input {
    width: 35px;
}
/***************************************************************************************/
.sorting_buttons
{
  padding: 0px 6px 4px 0px;
}
.sorting_buttons svg {
    cursor: pointer;
}
.content
{
  display: flex;
  align-items: center;
}

/***************************************************************************************/
.summaryContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 98%;
}
.urlId {
  width: 135px;
  margin-right: 8px;
  font-weight: bold;
}
.url {
  margin-right: 8px;
  color: #1976d2;
  user-select: text;
  flex: 1;
  text-align: center;
}
.url svg
{
  margin: 0px 0px -4px 0px;
}
.total_keywords_count
{
  width: 55px;
  margin-right: 8px;
  color: #1976d2;
  font-weight: bold;
}
.match_very_good,
.match_good,
.match_somewhat,
.match_bad,
.match_very_bad,
.match_not_analyzed
{
  width: 85px;
}
/*******************************************  M a t c h i n g  ********************************************/
.match_very_good {
  color: #00a600;
  font-weight: bold;
}

.match_good {
  color: #9da700;
  font-weight: bold;
}

.match_somewhat {
  color: #d0c60b;
  font-weight: bold;
}

.match_bad {
  color: #c26015;
  font-weight: bold;
}

.match_very_bad {
  color: #9b2b2b;
  font-weight: bold;
}

.match_not_analyzed {
  color: #a1a1a1;
  font-weight: bold;
}

.color-sample-container {
  margin: -40px 0px 0px 0px;
  padding: 10px 20px 10px 5px;
  width: 100px;
  border-radius: 8px;
  border: 1px solid #b6b6b6;
  box-shadow: 4px 4px 5px 0px #adadad;
}

.color-sample {
  text-align: right;
  width: 100px;
  float: right;
}
/***************************************************************************************/
.keywordsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}
.keywordContainer {
  margin-right: 8px;
}
.keywordContainer .keyword {
    color: #1976d2;
}
.keywordContainer .count {
  color: red; /* Set the color you prefer for the count */
}


/***************************************************************************************/
.sectionContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.sectionLabel {
  font-weight: bold;
  margin-bottom: 8px;
}


.buttonContainer {
  display: flex;
  justify-content: flex-end; /* Align button to the right */
  margin-top: 16px; /* Add margin between sections and button */
}

/***************************************************************************************/


.blog-urls-data-container {
  max-height: 500px;
  overflow: auto;
  margin: 10px 0px 50px 0px;
  border-radius: 8px;
  border: 1px solid #b6b6b6;
  box-shadow: 5px 5px 12px 0px #adadad;
}

.blog-urls-data {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.url {
  font-weight: bold;
  margin-bottom: 5px;
}

.scraped-data {
  max-height: 200px;
  overflow-y: auto;
}
