
.admin_page_heading{
  font-size: var(--heading-font-size);
  text-align: center;
  font-weight: 500;
  margin: 2% auto 1%;
}
.add-user-form {
    
    max-width: 600px;
    /* display: flex;
    flex-direction: column;
    gap: 5px; */
    max-height: 96vh;
    /* align-items: center; */
    
    
  }
  .modal_content_container{
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-height: 60vh;
    overflow: auto;
    width: 100%;
  }
  .page_header{
    font-size: var(--heading-font-size);
  }
  .modal_header{
    font-size: var(--text-font-size);
    text-align: center;
    margin: 20px auto 0px;
    font-weight: bold;
  }
  .modal_text{
    font-size: var(--text-font-size);
    text-align: center;
  }
  
  .form-group {
    margin: 20px 0;
    text-align: center;
    align-items: center;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
     /* border: 1px solid #ccc;  */
    border-radius: 5px;
  }
  .modal_box_form{
    margin: 10px;
    width: 80%;
    margin-left: 10%;
  }
  .form-group input[type="text"],
  .form-group input[type="email"],
  .form-group input[type="password"] {
    font-size: var(--text-font-size);
    
  }
  .add-user-btn {
    width: 50%;
    padding: 12px;
    background-color: #003aaf;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 25%;
    transition: background-color 0.3s ease;
    margin-top: 8px;
    margin-bottom: 25px;
  }
  
  .add-user-btn:hover {
    background-color: #286aab;
  }
  .action_icons_{
    display: flex;
    justify-content: left;
  }
  
  .action_icons_ svg{
    font-size: 20px;
  }
  
  .modal_form_input{
    background: transparent;
    border: none;
    border-bottom: 1px solid ;
  }
  .modal_form_input:focus {
    outline: none;
  }
  .formIcons{
    font-size: 14px;
    height: 24px;
    width: 28px;
    margin: 0 40px;
  }
  #model_box{
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    width: 600px;
    box-shadow: 240px;
    padding: 4px;
    background-color: var(--background-temp);
    color: var(--text-primary);
  }
  .modal_textarea{
    width: 100%;
    font-size: 15px;
    border-radius: 4px;
    padding-left: 8px;
    font-family:sans-serif;
    min-width: 537px;
    max-width: 537px;
    min-height: 40px;
    max-height: 100px;
  }
  .table_action_icon{
    font-size: 10px;
    color: var(--text-primary);
  }

