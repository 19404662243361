

/* width */
/* Scrollbar */
/* Customize the width and height of the scrollbar */
body{
  scrollbar-width: 1px;
}
/* body::-webkit-scrollbar {
  width: 1px; 
  height: 5px; 
  
} */
body::-webkit-scrollbar {
  display: none;
  
}
/* Style the track (background) of the scrollbar */
body::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}

/* Style the draggable part of the scrollbar */
body::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
}

/* Style the scrollbar thumb on hover */
body::-webkit-scrollbar-thumb:hover {
  cursor: grabbing;
  background: #555; /* Color of the scrollbar thumb on hover */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
 
}


