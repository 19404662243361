.choose_opurtuinity_container
  .tab_content_container
  .opportunity_match
  .header_container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* margin: 1% 0; */
  text-align: left;
}
.choose_opurtuinity_container
  .tab_content_container
  .opportunity_match
  .header_container
  .header {
  font-size: var(--heading-font-size);
}

.choose_opurtuinity_container
  .tab_content_container
  .opportunity_match
  .header_container
  .sub_header {
  font-size: var(--text-font-size);
}
.tab_content_container .campaign-list {
  display: flex;
  gap: 35px;
  padding: 20px 0;
  flex-wrap: wrap;
}

.tab_content_container .start-advanced-campaign {
  background-color: var(--background-tirnary);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  width: 340px;
  height: 360px;
  color: var(--text-secondary);
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.tab_content_container .start-advanced-campaign .start-icon {
  font-size: 40px;
  background-color: #6a9ffc;
  color: var(--text-secondary);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.choose_opurtuinity_model_box_content_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 3% 4%;
}
.choose_opurtuinity_model_header {
  font-size: var(--heading-font-size);
}
.choose_opurtuinity_modal-actions {
  display: flex;
  justify-content: space-between;
  /* margin-top: 20px; */
  gap: 40px;
}
