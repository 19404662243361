.page_Section {
  display: flex;
  text-align: center;
  width: 100%;
  min-height: 100vh;
}

.leftSection_withMenu {
  display: flex;
  flex-direction: column;
  width: 63px;
  flex-basis: 63px;
  background: var(--header-secondary);
  color: var(--text-primary);
}

.rightSection_withMenu {
  display: flex;
  width: calc(100% - 63px);
  overflow-y: hidden;
  background: var(--background-default);
  color: var(--text-primary);
}

.headerSection_withMenu {
   position: fixed;
  height: 56px;
  width: 100%;
  z-index: 1;
  background: var(--background-default);
  color: var(--text-primary);
  display: flex;
  flex-basis: 16px;
}
.rightSection_withOutMenu{
  /* background: var(--header-secondary); */
  color: var(--text-primary);
}
.rightSection_withMenu_without_loggedIn{
  background: var(--header-secondary);
  color: var(--text-primary);
  display: flex;
  overflow-y: hidden;


}
.bodySection {
  
  background: var(--background-default);
  color: var(--text-primary);
  width: 98%;
  margin: 4.5% 1% 0 1%;
}

.bodySection_without_loggedIn{
  background: var(--background-default);
  color: var(--text-primary);
  margin: 0% 1% 0 1%;
}



/* ---------------------------------------------------------------------------------------------------- */

.auto_image_change {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 0;
  transition: background-image 2.5s ease-in-out;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  pointer-events: none;
}

.page_container.home .HomepageHeaderSection
{
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  background: #00000042;
}
/*
.HomepageHeaderSection
{
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 55px;
  color: #fff;
  box-shadow: 0px 0px 3px #fff;
  display: flex;
  background: #00000042;
}
.HomepageHeaderSection > * {
    float:left;
    height: 55px;
}
.logo
{
  width: 150px;
  margin-left: 25px;
  background-repeat: no-repeat;
  background-position: -43px -32px;
  background-size: 115% 250%;
  background-position-x: center;
}
.menu {
    display: flex;
    justify-content: center;
    flex-grow: 1;
}
.menu > button
{
  margin-left: 15px;
  margin-right: 15px;
  color: #fff;
  font-weight: 100;
  font-size: 11px;
}
.user {
  width: 48px;
  height: 48px;
  margin: 2px 10px;
  background-repeat: no-repeat;
  background-position: 0px -1px;
  background-size: 214% 154%;
  background-position-x: center;
  border-radius: 38%;
  border: 2px solid #5a5959;
}
*/

/*************************************************************************************************************/

.PageContentSection {
    left: 0;
    width: 100%;
}
.SolutionsSection
{
  display: flex;
  justify-content: center;
  text-align: center;
}
.Solution
{
  width: 350px;
  height: 300px;
  margin: 100px 25px 25px 50px;
  padding: 5px;
  box-shadow: 1px 1px 4px 1px #c8c8c8;
  border-radius: 10px;
}
.SolutionHeader
{
  height: 25px;
  padding: 5px;
  box-shadow: 0px 1px 1px -1px #fff;
}
.SolutionDescription
{
  height: 200px;
  padding: 5px;

}
.SolutionButton
{
  height: 50px;
  padding: 5px;

}

.SolutionButton > button
{
  color: #fff;
  font-weight: 100;
  font-size: 11px;
}




/*************************************************************************************************************/

.robot {

}



/*************************************************************************************************************/
