/* PrimarySection.css */

/* Styling for the primary section container with fade-in animation */
.primarySection {
  display: contents;
  
  animation: fadeIn 1s ease-in-out forwards; /* Apply fade-in animation */
  height: 100vh;
}

/* Keyframes for the fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Styling for the content container */
.contentContainer {
  /* display: inline-flex; */
  justify-content:center;
  /* margin-top: 150px; */
  width: 100%;

  /* margin-bottom: 200px;
  margin-left: 20px; */
}

/* Styling for the text box */
.textBox {
  /* text-align: center;
  width: 50%; */
  padding-top: 10px; 
  margin: 1%;
}

/* Styling for the main heading */
.textBox_heading {
  /* margin-top: 5px; */
  font-size: var(--heading-font-size) ; /* Increased font size */
  margin-bottom: 15px;
  font-weight: 600;
  padding-right: 10px;
  
  /* margin-top: 12px; */
  /* font-weight: bolder; */
  /* text-shadow: 2px 2px 4px #0000004d; Text shadow for depth */
}


/* Responsive styling for the main heading */
@media (max-width: 442px) {
  .textBox_heading {
    font-size: var(--text-font-size); /* Decreased font size for smaller screens */
   
    
  }
}

/* Styling for the subheading */
.textBox_desc {
  font-size: var(--text-font-size);
  margin-bottom: 3%;
  width: 100%;
  /* margin-left: -205px; */
  background: var(--background-secondary);
  height: 26%;
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); */
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding-right: 10px;
}
.textBox_desc_heading{
  padding-top: 40px;
    font-size: 2.8rem;
    font-weight: 600;
 
    /* text-shadow: 2px 2px 4px #0000004d; */
   
}
.textBox_desc_slogan{
  /* text-shadow: 2px 2px 4px #0000004d; */
  font-size: 1.2rem;
}

.textBox_desc_button{
  margin-top: 6%;
  
}

/* Styling for the image container */
.aiImage {
  width: 40%; /* Adjust the width as needed */
}

/* Styling for the images */
.aiImage img {
  width: 100%;
  height: auto;
  align-self: flex-end; /* Align the image to the right */
}
.textBox_desc_credit{
  margin-top: 1%;
}
