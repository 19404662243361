:root {
  --font-size: 13px;

}

.listItemButton.active .listItemIcon.icon {
  color: #5E92D1; /* Change the color to blue */
}

.listItemButton.active .MuiTypography-body1 {
  color:#5E92D1; /* Change the color to blue */
}

.drawer {
  position: fixed;
  z-index: 2;
  /* box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1); */
}

.drawerHeader {
  display: flex;
  align-items: center;
  margin: 16px;
}

.headerLeft {
  display: flex;
}

.headerRight {
  display: flex;
  justify-content: center;
  width: 100%;
  height:18px;
  
}

.companyName {
  cursor: pointer;

  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
#leftsection_list{
  padding-top: 3px;
}

.listItemButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  margin: 8px 0;
}
.icon svg{
  font-size: 20px;
}

.listItemButton:hover {
  /* background-color: rgba(200, 200, 200, 0.7); 
}  

.listItemIcon {
  font-size: 1.5em;
  /* color: var(--primary-color); */
  /* margin-right: 10px; */
}

/* .plusMinusIcon {
  margin-top: 3px;
  margin-right: 7px;
  transition: transform 0.5s ease;
} */

/* .listItemButton.drawerOpen .plusMinusIcon {
  transform: rotate(45deg);
} */

/* .subMenu {
  border-left: 2px solid #8d838351; 
  padding-left: 10px;
} */

/* Additional Styling for Additional Items */
.listItem.additionalItem .listItemIcon {
}

.listItem.additionalItem .plusMinusIcon {
  display: none;
}

