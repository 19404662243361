

.bot-component {
  position: fixed; /* Change from absolute to fixed */
  bottom: 20px; /* Adjust bottom distance as needed */
  right: 20px; /* Adjust right distance as needed */
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 300px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  font-family: Arial, sans-serif;
  z-index: 9999; /* Adjust z-index as needed */
}

.bot-header {
  background-color: #3498db;
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.bot-header img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.minimize-button {
  background: #fff;
  cursor: pointer;
  height: 7px;
  width: 20px;
  margin-top: 15px;
}

.chat-container {
  padding: 10px;
  height: 200px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.message {
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  word-wrap: break-word;
  max-width: 70%; /* Adjust the maximum width as needed */
  align-self: flex-end; /* Align sent messages to the right */
}

.message.user_message {
  background-color: #bee3fc;
  color: #000;
  border: none;
}

.message.bot {
  background-color: #b8e7d5;
  color: #333;
  align-self: flex-start; /* Align bot replies to the left */
}

/* .input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.input-container input {
  flex-grow: 1;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.input-container button {
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.input-container button:hover {
  background-color: #2980b9;
}
 */

/**/


.chat-container.minimized
{
  display: none;
}
.bot-component.minimized
{
  width: 60px;
}
.bot-component.minimized .bot-header .minimize-button
{
  display: none;
}




/**/
