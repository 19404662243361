.custom_button .button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
 .custom_button .reset_button {
    background-color: transparent; /* Red for reset */
    color: #d9534f;
    border:1px solid #d9534f;

  }
  
 .custom_button .reset_button:hover {
    background-color: #d9534f;
    color: var(--button-select_text);
  }
  
 .custom_button .proceed_button {
    background-color: transparent; /* Green for proceed */
    color: #4b91ca;
    border:1px solid #4b91ca;
  }
  .custom_button .proceed_button:hover {
    background-color: #4b91ca; /* Green for proceed */
    color: var(--button-select_text);
  }
 
  