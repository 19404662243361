.black_list_page_container {
  display: flex;
  font-size: 42px;
  align-items: center;
  height: 70vh;
  text-align: center;
  justify-content: center;
}
.black_list_save_modal_button_container{
  display: flex;
  justify-content: center;
  gap: 5%;
  margin: 5% 0;
}
.black_list_save_modal_button{
  display: flex;
  width: 14rem;
  align-items: center;
}
.black_list_bloggers_container{
  width: 100%;
  display: flex;
  justify-content: space-between;
}


.black_list_bloggers_container_section{
  width: 48%;
}
#black_list_drawer_menuitem{
  height: 31px;
}
.drawer_with_content_container{
  display: flex;
  position: fixed;
  /* justify-content: center; */
  margin: 0 0 5%;
  gap: 20px;
  padding: 0 0px 0 20px;
  width: 90%;
}
.drawer_content_container {
  width: 100%;
    background-color: var(--background-primary);
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    height: 425px;
    overflow-y: auto;
    
}
.drawer_content_inner {
  height: 100%; /* Ensure full height for inner container */
}
#black_list_drawer_menuitem_content{
  height: 55px;
}

.drawer_menuitem_container:focus {
  outline: none; /* Remove default focus outline */
}
.MuiDrawer-paper {
  position: relative; /* Ensure the drawer is positioned relative to the container */
}
.drawer_menuitem_container:not(#menu-item-3) {
  height: 300px;
}
.black_list_drawer_text{
  display: flex;
    align-items: center;
    justify-content: space-between;
}
.black_list_drawer_header_container{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  padding: 0 16px;
}

.black_list_drawer_header{
  font-size: var(--header-font-size);
  font-weight: bolder;
}
#black_list_drawer{
  border-radius: 10px;
}
.black_list_drawer_header_with_container{
  /* box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.2);
  background: var(--background-secondary); */
  border-radius: 10px;
  padding: 5px 0 10px;
  margin: 1% 0 4%;
}
.blacklit_header_with_button_container{
  display: flex;
  height: 90px;
  padding: 8px 0px 0px 20px;
  justify-content: space-between;
}
#black_list_drawer_menu{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.black_list_header_container{
  text-align: left;
  
}
.black_list_drawer_menu_open_icon{
  font-size: 12px;
}
.black_list_drawer_menu_close_icon{
  padding: 0 13px 0 0;
  font-size: 12px;
}
.black_list_header_container_text{
  font-size: var(--heading-font-size);
    font-weight: 500;
}
#selected {
  background-color: var(--background-primary); /* Adjust the color as needed */
}
.remove_or_filter_url_container{
  display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: left;
    margin: 0 0 0 20px;
    padding: 35px 20px;

}
.remove_or_filter_url_content{
  display: flex;
  gap: 15px;
  align-items: center;

}
#black_list_button_content{
  width: 126px;
    margin: 0px 0 0 3px;
    height: 32px;
}
.black_list_header_button_container{
  display: flex;
  gap:10px;
  margin: 13px 18px 0px 0px;
}
#black_list_header_button_content{
  width: 160px;
  height: 38px;
}

.remove_or_filter_url_button_container{
  display: flex;
  gap: 20px;
}

.filter_tlds_button_container{
  display: flex;
  gap: 20px;
  margin: 8px 0;
}

.filter_tlds_tabs{
  display: flex;
  gap: 5px;
}


.filter_tlds_tab .filter_tlds_tab_active{
  background-color: var(--background-temp);
  border-bottom: 1px solid var(--logo-text);
}

.filter_tlds_tab_content{
  height: 150px;
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    /* gap: 30px; */
    padding: 10px;
    border: 1px dashed var(--tableBorder-border);
}

.tld_content{
  display: flex;
  height: 32px;
  width: fit-content;
  align-items: center;
  margin: 0 12px;
}
.select_all_container{
  display: flex;
  gap: 10px;
}
.tld_content_input_text{
  margin: 0 5px;
}
.filter_tlds_container {
  display: flex;
  /* height: 430px; */
}

.filter_tlds_sidebar {
  width: 100px;
  /* border-right: 1px solid #ccc; */
  background-color: var(--background-primary);
  border-radius: 10px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2)
}

.filter_tlds_tabs_vertical {
  display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 50px 0 0 0;

}

.filter_tlds_tab {
  padding: 10px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  text-align: left;
  width: 100%;
  font-size: var(--text-font-size);
}

.filter_tlds_tab_active,
.filter_tlds_tab:hover {
  background-color: var(--background-temp);
  border-bottom: 1px solid var(--logo-text);
  font-weight: bold;
}

.filter_tlds_content {
  /* height: 430px; */
  width: 85%;
  padding: 10px 10px 10px 0;
  border-radius: 10px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2)
}

.select_all_container {
  margin: 0 0px 10px 23px;
}

.filter_tlds_tab_content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  
  /* max-height: 300px;  */
}

.tld_content {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.tld_content_input_text {
  margin-left: 5px;
  font-size: var(--text-font-size);
}

.filter_tlds_button_container {
  display: flex;
  justify-content: flex-start;
  margin: 13px 0 0 27px;
}
.filter_tlds_tab_heading{
  font-size: var(--text-font-size);
  text-align: left;
}

.filter_tlds_tab_container{
  /* height: 318px; */
  overflow-x: auto;
  padding: 0px 30px;
  white-space: nowrap; 
  scroll-behavior: smooth;
}

.filter_tlds_tab_content_container{
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 1% 0 0 0;
}
.filter_tlds_header_sidebar_container{
  padding: 31px 0 0 46px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}



.filter_by_domain_container{
  padding: 40px 45px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}