/* AboutUs.css */

:root {
  --heading-font-size: 36px;
  --text-font-size: 18px;
  --primary-color: black;
  --gradient-background: white;
}

body {
  margin: 0;
  padding: 0;
}

#aboutUs {
  width: 98%;
  padding: 50px 0;
  text-align: center;
  margin: 1% 1% 0px 1%;
}

/* #aboutheading {
  font-size: var(--heading-font-size);

  font-weight: bold;
  padding: 12px 24px;
  border-radius: 10px;
  @media (max-width: 442px) {
    font-size: var(--text-font-size);
  }
} */

#boxContainer {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to a new row */
  justify-content: center;
  gap: 30px; /* Space between flex items */
  width: 100%;
  margin-bottom: 5%;
    height: 30%
  
}

.box {
  /* width: calc(33.33% - 16px); */
  flex-basis: calc(33.3333% - 30px);
  border-radius: 10px;
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); */
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background: var(--background-secondary);
}

.box:hover {
  transform: scale(1.05);
  /* box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3); */
}

#box-heading {
  font-weight: bolder;
  /* margin-bottom: 16px; */
  font-size: 20px;
  text-align: left;
  padding: 0px 16px;
  /* text-shadow: 2px 2px 4px #0000004d; Text shadow for depth */

}

.box-paragraph {
  padding: 16px;
  font-size: var(--text-font-size);
  text-align: left;
}

.box-icon {
  text-align: left;
  padding: 16px;
  font-size: 40px;
  margin-bottom: 18px;
}

@media (max-width: 768px) {
  .box {
    width: 100%;
    margin-bottom: 16px;
  }
}