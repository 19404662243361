svg.OpenInNewIcon {
    cursor: pointer;
}
.modalContentCantainer
{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15px 0px 0px 0px;
  border-radius: 5px;
  /* border: 1px solid #b7b7b7;s */
  padding: 15px;
}
.text_area_of_link_insertion{
  background: var(--container-body);
 color: var(--text-primary);
 /* padding: 16.5px 14px;
 height: 65px;  */
 font: inherit;
 box-sizing: border-box;
 width: 100%;
 line-height: 1.5;
 padding: 14px;
 border-radius: 6px 6px 0 6px;
 border: 1px solid gray;
}
.uploadedFileContainer {
  max-height: 350px;
  overflow: auto;
}

.load_and_upload_button_container{
  display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 1% auto 1%;
}

.FindContactfileUploadContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.load_clear_button_container{
  display: flex;
  flex-direction: column;
}
.load_clear_button{
  margin: 1% 0;
}