.explore_blog_page_container{
    margin: 2% 0;
}

.explore_blog_page_heading{
    margin: 0 0 1% 0px;
}

.explore_blog_page_heading_text{
    font-size: var(--heading-font-size);
}

.explore_blog_page_details{

}