.quickvarify_screenshot_page {
  display: flex;
  gap: 10px;
  margin: 2% 0;
  width: 100%;
  justify-content: space-between;
  padding: 0 1%;
}
.quickvarify_main_content_container {
  width: 79%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.quickvarify_main_content_container .header {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.quickvarify_main_content_container
  .header
  .header_input_text_with_button_container {
  display: flex;
  gap: 20px;
}

.quickvarify_main_content_container
  .header
  .header_input_text_with_button_container
  .header_input_text {
  display: flex;
  gap: 10px;
  align-items: center;
}
.quickvarify_main_content_container
  .header
  .header_input_text_with_button_container
  .header_input_text
  .status_container {
  padding: 7px 30px;
  border: 1px solid var(--background-prev);
  border-radius: 20px;
}
.quickvarify_main_content_container
  .header
  .header_input_text_with_button_container
  .yes_no_button_container {
  display: flex;
  gap: 15px;
  align-items: center;
}
.quickvarify_main_content_container
  .header
  .header_input_text_with_button_container
  .yes_no_button_container
  .priamry {
  background-color: transparent; /* Green for proceed */
  color: var(--button-primary_bg);
  border: 1px solid var(--button-primary_bg);
}
.quickvarify_main_content_container
  .header
  .header_input_text_with_button_container
  .yes_no_button_container
  .priamry:hover {
  background-color: var(--button-primary_bg); /* Green for proceed */
  color: var(--logo-text);
}

.quickvarify_main_content_container
  .header
  .header_input_text_with_button_container
  .yes_no_button_container
  .secondary {
  background-color: transparent; /* Red for reset */
  color: var(--button-secondary_bg);
  border: 1px solid var(--button-secondary_bg);
}
.quickvarify_main_content_container
  .header
  .header_input_text_with_button_container
  .yes_no_button_container
  .secondary:hover {
  background-color: var(--button-secondary_bg); /* Green for proceed */
  color:  var(--button-select_text);
}

.quickvarify_main_content_container
  .header
  .header_input_text_with_button_container
  .yes_no_button_container
  button {
  padding: 10px 25px;
  height: 38px;
  font-size: var(--text-font-size);
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.quickvarify_main_content_container
  .header
  .header_input_text_with_button_container
  .yes_no_button_container
  .priamry.selected {
  background-color: var(--button-primary_bg); /* Green for YES selected */
  color:  var(--button-select_text);
}

.quickvarify_main_content_container
  .header
  .header_input_text_with_button_container
  .yes_no_button_container
  .secondary.selected {
  background-color: var(--button-secondary_bg); /* Red for NO selected */
   color:  var(--button-select_text);
}

.quickvarify_main_content_container
  .header
  .header_input_text_with_button_container
  .yes_no_button_container
  button.selected {
  cursor: not-allowed; /* Disable cursor */
}

.quickvarify_main_content_container .navigation_and_select_box_container {
  display: flex;
  align-items: center;
  /* gap: 60px; */
}
.quickvarify_main_content_container
  .navigation_and_select_box_container
  .navigation_pagination {
  display: flex;
  gap: 2px;
  width: 5%;
}
.quickvarify_main_content_container
  .navigation_and_select_box_container
  .navigation_pagination
  svg {
  font-size: 26px;
  color: var(--text-secondary);
}

.quickvarify_main_content_container
  .navigation_and_select_box_container
  .input_container {
  display: flex;
  gap: 20px;
  width: 90%;
  text-align: left;
}

.quickvarify_main_content_container
  .navigation_and_select_box_container
  .input_container
  .input_content_container
  .css-1ytf8rl-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,
.quickvarify_main_content_container
  .navigation_and_select_box_container
  .input_container
  .input_content_container
  .css-62kpdw-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  width: 100%;
  height: 40px;
}
.quickvarify_main_content_container
  .navigation_and_select_box_container
  .input_container
  .input_content_container {
  width: 50%;
}

.quickvarify_main_content_container
  .navigation_and_select_box_container
  .input_container
  .input_content_container
  .input_content_url_container {
  padding: 10px 20px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: nowrap; /* Prevent wrapping to multiple lines */
  overflow: hidden;
  text-overflow: ellipsis;
  height: 45px;
  border: 1px solid var(--text-icon);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.quickvarify_main_content_container
  .navigation_and_select_box_container
  .input_container
  .input_content_container
  .input_select_content_url_container {
  height: 45px;
  border: 1px solid var(--text-icon);
  border-radius: 10px;

  /* Box shadow */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.quickvarify_screenshot_page .action_buttons button {
  background-color: var(--background-default);
  border: 1px solid #555;
  color: var(--logo-text);
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.quickvarify_screenshot_page .content_container {
  display: flex;
  gap: 20px;
  width: 21%;
  text-align: left;
}
.quickvarify_screenshot_page .screenshot_image_container {
  display: flex;
  width: 100%;
  height: 800px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.quickvarify_screenshot_page .screenshot_image {
  width: 100%;
  display: flex;
  justify-content: flex-start; /* Align items to the top */
  align-items: flex-start;
  background-color: var(--background-default);
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  height: 800px;
  overflow: auto;
  border: 1px solid var(--logo-text);
}

.quickvarify_screenshot_page .screenshot_image img {
  width: 100%;
  border-radius: 8px;
}
.screenshot_image_background {
  width: 100%; /* or specify a fixed width */
  height: 100%; /* or specify a height */
  background-size: cover; /* Ensures the image covers the entire div */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
}
.quickvarify_screenshot_page .matches_section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 940px;
  overflow: auto;
  scrollbar-width: none;
}
.quickvarify_screenshot_page .go_back_button {
  background: var(--background-prev);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  cursor: pointer;
}
.quickvarify_screenshot_page .go_back_button svg {
  color: var(--text-tertiary);
  font-size: 25px;
}

.quickvarify_screenshot_page .top_match_section,
.quickvarify_screenshot_page .other_matches_section {
  padding: 10px;
  border-radius: 8px;
}

.quickvarify_screenshot_page .match_item {
  background-color: #444;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
  color: #bbb;
}

.quickvarify_screenshot_page .match_item .highlighted {
  border: 2px solid #4caf50; /* Green border for highlighted item */
  background-color: rgba(76, 175, 80, 0.1); /* Light green background */
}

.quickvarify_screenshot_page .match_item.selected {
  background-color: var(--background-default);
  border: 1px solid #888;
}

.quickvarify_screenshot_page .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}
.quickvarify_screenshot_page .pagination .pagination_icon_container svg {
  font-size: 20px;
  color: var(--text-secondary);
}
.quickvarify_screenshot_page .pagination .pagination_icon_container {
  background-color: var(--tableBorder-border2);
  color: var(--logo-text);
  padding: 4px 0px;
  border-radius: 5px;
}

.quickvarify_screenshot_page .top_match_section {
  background-color: var(--background-tirnary);
  border-radius: 8px;
  padding: 16px;
  font-family: Arial, sans-serif;
  width: 300px;
}

.quickvarify_screenshot_page .url_label {
  font-weight: bold;
  font-size: var(--text-font-size);
  margin-top: 6px;
  color: var(--text-primary);
  display: flex;
  justify-content: space-between;
}

.quickvarify_screenshot_page .url_value {
  font-size: var(--text-font-size);
  margin-bottom: 8px;
  max-width: 100%; /* Adjust the max width as needed to fit within the item */
  word-wrap: break-word; /* Break long words to wrap them to the next line */
  overflow-wrap: break-word; /* Ensure words break properly in case of overflow */
  white-space: normal;
  font-weight: 200;
  color: var(--text-second);
}

.quickvarify_screenshot_page .similarity_section {
  display: flex;
  /* align-items: center; */
  width: 50%;
  flex-direction: column;
}

.quickvarify_screenshot_page .similarity_label {
  font-weight: bold;
  color: var(--text-primary);
  font-size: var(--text-font-size);
}

.quickvarify_screenshot_page .similarity_score {
  font-size: 12px;
}

.quickvarify_screenshot_page .page_type_label {
  font-weight: bold;
  font-size: var(--text-font-size);
  color: var(--text-primary);
}

.quickvarify_screenshot_page .page_type_value {
  font-size: var(--text-font-size);
  color: var(--text-second);
}

.quickvarify_screenshot_page .no_match_message {
  color: #ff6666;
  font-size: var(--text-font-size);
  text-align: center;
}

.quickvarify_screenshot_page .page_type_and_similarity_section_container {
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}
.quickvarify_screenshot_page .page_type_container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 50%;
  text-align: left;
}

.quickvarify_screenshot_page .content_container .other_matches_section {
  width: 300px;
  background-color: var(--background-paper);
  border-radius: 8px;
  padding: 16px;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  /* max-height: 603px; */
}

.quickvarify_screenshot_page .content_container .section_title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.quickvarify_screenshot_page .content_container .items_container {
  display: flex;
  flex-direction: column;
  /* overflow-y: auto; */
  /* max-height: 450px; */
  margin: 5px 0px;
  gap: 15px;
  /* scrollbar-width: none; */
}

.quickvarify_screenshot_page .content_container .match_item {
  background-color: var(--background-primary);
  border-radius: 8px;
  position: relative;
  padding: 12px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.quickvarify_screenshot_page .content_container .match_item:hover {
  background-color: var(--hover-primary);
}

.quickvarify_screenshot_page .content_container .url_text {
  font-size: var(--text-font-size);
  color: #b0b0b3;
  margin-bottom: 4px;
}

.quickvarify_screenshot_page .content_container .similarity_text {
  display: flex;
  align-items: center;
  font-size: var(--text-font-size);
  color: #ffffff;
  margin-top: 8px;
}

.quickvarify_screenshot_page .content_container .similarity_bar_container {
  width: 65%;
  background-color: #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  height: 4px;
}

.quickvarify_screenshot_page .content_container .similarity_bar {
  height: 100%;
  transition: width 0.3s ease;
}

.quickvarify_screenshot_page .content_container .similarity_green {
  background-color: green;
}

.quickvarify_screenshot_page .content_container .similarity_red {
  background-color: red;
}
.quickvarify_screenshot_page .content_container .similarity_orange {
  background-color: orange;
}

.quickvarify_screenshot_page .content_container .text_green {
  color: green;
}

.quickvarify_screenshot_page .content_container .text_orange {
  color: orange;
}

.quickvarify_screenshot_page .content_container .text_red {
  color: red;
}

.quickvarify_screenshot_page .content_container .page_type {
  font-weight: bold;
}

.quickvarify_screenshot_page .content_container .find_matches_button {
  width: 100%;
  padding: 10px;
  background-color: #3a3a3c;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  margin-top: auto; /* Pushes the button to the bottom */
}

.quickvarify_screenshot_page .content_container .find_matches_button:hover {
  background-color: var(--background-hover);
}

.keywords_content_container {
  display: flex;
  flex-direction: column;
  gap: 5px;

  margin: 10px 0px 15px;
}
.keywords_container_header {
  font-weight: bolder;
  color: var(--text-primary);
}
.no_keywords_message{
  color: var(--text-second);

}
.keywords_container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  font-weight: 100;
  font-size: 14px;
}
.match_button {
  padding: 2px 6px;
  border: 1px solid var(--text-second);
  border-radius: 5px;
}

/* Loading container styles */
.loading_screenshot_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

/* Loading message with animation */
.loading_screenshot_message {
  margin-top: 10px;
  color: var(--text-primary);
  font-size: 1.2rem;
  font-weight: 500;
  animation: fadeInPulse 2s infinite ease-in-out;
  text-align: center;
}

/* Fade-in pulsing animation */
@keyframes fadeInPulse {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 0.5;
    transform: scale(1);
  }
}

.match_content_highlighted_yes {
  border: 1px solid #24970d;
}

.match_content_highlighted_no {
  border: 1px solid #ce1212;
}
.url_label_title_confirmed_container {
  
  position: absolute;
  top: -12px;
  right: 15px;
  background-color: #3ea74f;
  color: var(--logo-text);
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 6px 16px;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  clip-path: polygon(0 0, 100% 0, 95% 100%, 5% 100%);
}
.url_label_title_rejected_container {
  position: absolute;
  top: -12px;
  right: 15px;
  background-color: var(--button-secondary_bg);
  color: var(--logo-text);
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 6px 16px;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  clip-path: polygon(0 0, 100% 0, 95% 100%, 5% 100%);
}


.other_item_container{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.quick_verify_selected_item{
  border: 1px solid var(--border-primary);
  box-shadow: 0 0px 6px var(--border-primary);
}
.webpage_link {
  display: block; /* Ensure it behaves like a block for centering */
  text-align: center; /* Center text horizontally */
  margin:  auto; /* Center element horizontally */
  
  text-decoration: none; /* Remove underline */
  color: var(--text-primary); /* Default text color for dark mode */

 
  transition: background-color 0.3s, color 0.3s; /* Smooth color transitions */
}

