.PageContentSection {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 55px;
  width: 100%;
}

.SolutionsSection {
  display: flex;
  justify-content: center;
  text-align: center;
}

.Solution {
  height: auto;
  
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: var(--tableBody-temp);
  transition: transform 0.3s;
}

.Solution:hover {
  transform: translateY(-5px);
}

.SolutionHeader {
  font-size: var(--text-font-size);
  font-weight: bold;
  margin-bottom: 10px;
}

.SolutionDescription {
  font-size: var(--text-font-size);
  line-height: 1.6;
  margin-bottom: 20px;
}

.SolutionButton {
  display: flex;
  justify-content: center;
}

.SolutionButton > button {
  /* background-color: #3498DB; */
  color: var(--logo-text);
  font-size: var(--text-font-size);
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  /* transition: background-color 0.3s; */
}

