.workspace_details_container {
  
    display: inline-block;
    margin-top: 1%;
    width: 100%;
  }
  
  .workspace_record {
    background-color: var(--tableBody-temp);
    padding: 12px 30px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    margin: 14px 0;
  }
  
  .workspace_record_heading {
    font-weight: bold;
    font-size: var(--heading-font-size);
    /* color: #333; */
    height: 50px;
   
  }
  .workspace_record_heading_team_member {
    font-weight: bold;
    font-size: var(--text-font-size);
    /* color: #333; */
    padding-top: 1%;
  
  }
  
  .link_value {
    padding-top: 10% ;
    font-size: 1.6rem;
    color: #007bff; 
    height: 95px;
  }
#accordion_container{
  width: 80%;
  border-radius: 10px;
  margin: 1% 10% 1%;
  background-color: var(--background-primary);
}

.workspace_record.workspace_description {
  background-color: var(--tableBody-temp); /* Same background color as other records */
  padding: 20px; /* Increased padding for a description-like appearance */
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: flex-start; /* Align content to the top */
  justify-content: flex-start; /* Align content to the left */
  flex-direction: column; /* Stack items vertically */
  margin: 14px 0;
}

.workspace_record.workspace_description .workspace_record_heading {
  font-weight: bold;
  font-size: var(--heading-font-size);
  margin-bottom: 10px; /* Add some space below the heading */
}

.workspace_record.workspace_description .total_record_value {
  font-size: var(--text-font-size); /* Decrease font size slightly */
  text-align: left;
}

  
  .link_to_next_page_container {
    display: flex;
    justify-content: space-around;
    text-align: center;
    width: 80%;
    margin-left: 10%;
    margin-top: 1%;
  }
  
  .Link {
    height: auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: var(--tableBody-temp);
    transition: transform 0.3s;
    width: 35%;
  }
  
  .Link:hover {
    transform: translateY(-5px);
  }
  

  
  .LinkDescription {
    font-size: var(--text-font-size);
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .LinkButton {
    display: flex;
    justify-content: center;
  }
  
  .LinkButton > button {
    /* background-color: #3498DB; */
    /* color: var(--logo-text); */
    font-size: var(--text-font-size);
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    /* transition: background-color 0.3s; */
  }


.LinkDescription
{
  height: 120px;
  padding: 5px;

}



  