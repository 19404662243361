svg.OpenInNewIcon {
    cursor: pointer;
}
.blog-urls-selected{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1% 0;
}
.blog_url_heading{
    font-size: var(--heading-font-size);
    font-weight: 600;

}
.blog_url_function_container{
    margin: 1% 0;
}
.blog_url_text_area{
    margin: 1% 0;
}
.blog_url_create_scrape_button_container{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 2% 0;
    
}
.blog_url_black_list_button_container{
    position: absolute;
    right: 61px;
}
.blog_url_save_button_container{
    display: flex;
    justify-content: right;
    margin: 2% 10px 0px 0;
}
.blog_url_table_container{
    margin: 2% 0;
}
.breadcrumb_text{
    font-size: var(--text-font-size);
}
.modal_button_container{
    display: flex;
    justify-content: center;
    gap: 20px;
}
.edit_scrape_modal_header{
    margin: 0px 20px 0px 0px;
}

.edit_scrape_modal_container{
    padding: 10px 20px;
    border: 1px solid var(--tableBorder-border2);
    border-radius: 5px;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 10px;
}
.edit_scrape_modal_button_container{
    display: flex;
    justify-content: center;
}

.blog_page_skip_continue_button_container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-items: center;
}
.blog_page_skip_continue_button_sticker_text_container{
    display: flex;
    height: 25px;
    align-items: center;
}

.blog_page_scrape_text_progresbar_container{
    display: flex;
    gap: 10px;
}

.blog_page_scrapping_text_linear_bar_container{
    display: flex;
    flex-direction: column;
    gap: 26px;
}

.blog_url_scrapped_button_container{
    display: flex;
    justify-content: center;
    gap: 10px;
}

.blog_url_modal_content_cantainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;
    border-radius: 5px;
    gap: 20px;
}

.blog_url_modal_header{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.blog_url_modal_title{
    display: flex;
    font-size: var(--heading-font-size);
    justify-content: center;
    font-weight: 600;
}


.blog_url_modal_subheading{
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    font-size: var(--text-font-size);
}

.blog_url_modal_button_container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 0% 0 2%;
}

.blog_url_modal_summuary_content_container{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.blog_url_loading_modal_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.prepare_prospects_heading_container {
    display: flex;
    justify-content: left;
    width: 100%;
  }
  
  .prepare_prospects_heading_text {
    font-size: var(--heading-font-size);
    font-weight: 900;
  }
  
  .prepare_prospects_sub_heading_container {
    display: flex;
    justify-content: left;
    width: 100%;
  }
  
  .prepare_prospects_sub_heading_text {
    font-size: var(--text-font-size);
    line-height: 1.6;
    text-align: left;
  }
  
  .prepare_prospects_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 2% 0 0 0;
  }
  