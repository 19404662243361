.floating_menubar_container{
    text-align: right;
}

.fab {
    position: fixed;
    top: 20px; /* Adjust as needed */
    right: 20px; /* Adjust as needed */
  }

  .menu-item {
    width: 200px; /* Adjust width as needed */
  }
.speed_dial_action{
  width: fit-content;
}
#speed_dial_action_text{
  width: 150px;
}
.speed-dial-item {
  display: flex;
  align-items: center;
}
div.speed_dial_button button {
  height: 38px;
  width: 38px;
}
.menu-item-name {
  margin-left: 8px; /* Adjust this value as needed for proper spacing */
}
.active-menu-icon {
  background-color: #0ab9ff;
  /* color: #fff; Text color for active state */
}
/* FloatingMenuBar.css */

.speed-dial-action {
  white-space: nowrap;
  width: auto; /* Set to 'auto' to adjust dynamically based on content */
  max-width: 40%;
}
.floating_menu_icon:hover{
  background-color: var(--logo-text) !important;
  color: var(--background-temp) !important;
}
.floating_menu_icon.active {
  background-color:var(--logo-text) !important;
  color: var(--background-temp) !important;
}
.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  margin-left: 75%;
  width: auto;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  pointer-events: none; /* Disable pointer events by default */
  display: none; /* Hide backdrop by default */
}

.backdrop.open {
  pointer-events: auto; /* Allow interaction when open */
  display: block;
  width: auto; /* Show backdrop when open */
}
#speed_dial_flow{
  position: absolute;
  top: 64;
  right: 16;
}
.floating_menu_text{
  font-size: var(--text-font-size);
  border-radius: 5px;
  background-color: var(--flow_chart-background);
  padding: 4px 10px;
  font-weight: 600;
}
.floating_menu_text:hover{
  background-color: var(--logo-text) !important;
  color: var(--background-temp) !important;
}
.floating_menu_text.active {
  background-color:var(--logo-text) !important;
  color: var(--background-temp) !important;
}
#speed_dial_flow .MuiSpeedDialAction-staticTooltipLabel {
  padding: 0px !important; 
}


