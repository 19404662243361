/* ProportionSection.css */


.proportion-section {
  text-align: center;
  padding: 20px; 
  height: 90vh; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 60px;
  background-color: var(--background-default);
  color: var(--text-primary);
}

#proportionheading {
  font-size: var(--heading-font-size);
  margin-top: 15px; 
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 10px;

  @media (max-width: 442px) {
    font-size: var(--text-font-size);
  }
}

/* Styling for the card container */
.card-container {
  display: flex;
  justify-content: space-around;
  gap: 20px; /* spacing between cards */
  margin-top: 20px; /* equivalent to 2rem */
  flex: 1; /* Take up the available vertical space */
}

/* Styling for the card */
.card {
  flex: 1;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.sub-heading {
  font-weight: bolder;
  margin-bottom: 10px; 
  font-size: var(--text-font-size);
}

.paragraph {
  font-size: var(--text-font-size);
}
