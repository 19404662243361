/* KeywordsSearch.css */

.keywords_search_container {
    display: flex
;
    flex-direction: column;
    background-color: var(--background-primary);
    color: var(--text-primary);
    padding: 20px;
    border-radius: 10px;
    /* max-width: 300px; */
    /* margin: 0 auto; */
    font-family: "Arial", sans-serif;
    width: 100%;
}

.keywords_search_container .keywords_search_header_container{
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 25px;
    margin-bottom: 15px;
}
.keywords_search_container  .keywords_search_header_text{
    
}
/* Pre-defined Matches Section */
.predefined_matches {
    margin-bottom: 24px;
}

.predefined_matches .predefined_matches {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #b5b5c4; /* Slightly gray text for the section header */
}

.matches_list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.match-button {
    background-color: var(--background-default);
    color: var(--text-second);
    font-size: var(--text-font-size);
    padding: 6px 12px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: 1px solid;

}

.match-button.active {
    background-color: #4c6ef5; /* Highlighted blue for active button */
}

.match-button:hover {
    background-color: #3e3e5c; /* Hover state */
}

/* Manual Search Section */
.manual_search {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
}

.manual_search_header {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #b5b5c4;
}

.keywords_search_container .keywords_search_container_search_bar {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: column;
}

.search_button {
    background-color: #4c6ef5;
    color: #ffffff;
    font-size: 12px;
    padding: 6px 16px;
    border-radius: 8px;
    text-transform: none;
    box-shadow: none;
}

.search_button:hover {
    background-color: #3e5ec1; /* Slightly darker blue on hover */
}

/* AutoComplete Text Field Styling */
.MuiAutocomplete-root {
    width: 100%;
}

.MuiOutlinedInput-root {
    background-color: var(--background-default);
    border-radius: 8px;
    color: var(--text-primary);
}

.MuiOutlinedInput-notchedOutline {
    border-color: #3e3e5c; /* Border matches the dark theme */
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #4c6ef5;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #4c6ef5; /* Highlighted on focus */
}

.MuiInputLabel-root {
    color: #b5b5c4; /* Lighter label color */
}

.MuiInputLabel-root.Mui-focused {
    color: #4c6ef5; /* Highlighted label */
}

