.flow_Container
{
  width: 100%;
  height: 90vh; /* Adjust the height according to your requirement */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2% 0 0 0; 
}

.react-flow__renderer {
  margin: auto; /* Center the ReactFlow content */
  margin-left: 25%;
}

.flow_Container .react-flow .react-flow__panel.react-flow__minimap.bottom.right {
    display: none;
}

.react-flow__node-default {
    font-size: 12px;
    font-weight: bold;
    /* color: var(--react_flow-text); */
}

.react-flow__node-default:hover {
  font-size: 13px;
  box-shadow: 1px 1px 3px 0px #153a94 !important;
}

.react-flow__node-default.instruction
{
  width:250px;
}
.react-flow__node-default.instruction:hover
{
  font-size: 12px;
  /* color: var(--react_flow-text); */
  border: 1px solid var(--react_flow-border);
  box-shadow: none;
}

.react-flow__node-default.work_done_here {
  color: #19890d;
  border: 1px solid var(--react_flow-border);
}

.react-flow__node-default.work_done_here:hover {
  box-shadow: 1px 1px 3px 0px #095a00 !important;
}


.instruction_texts
{
  text-align: left;
}
.instruction_text1
{
    margin: 10px 0px 10px 0px;
    background: var(--react_flow-first);
    width: fit-content;
    border-radius: 5px;
    padding: 5px;
}
.react-flow__node-default,
.react-flow__node-input,
.react-flow__node-output,
.react-flow__node-group {
  width: 180px;
  
  
  color: var(--react_flow-text);
  
  border:1px solid var(--react_flow-border);
  background-color: var(--react_flow-first);
}

.instruction_text {
    margin: 10px 0px;
    background: var(--react_flow-second);
    width: fit-content;
    border-radius: 5px;
    padding: 5px;
    position: relative;
}

.react-flow__controls-button {
  border: none;
  /* color: var(--react_flow-text); */
  
  background-color: #cbcbcb;
  border-bottom: 1px solid var(--react_flow-border);
  
}

.react-flow__panel.left {
  left: 20;
  bottom: 100;
}
/* .instruction_text::before {
  content: '';
  position: absolute;
  top: 0;
  left: -11px;
  border-right: 19px solid var(--react_flow-border);
  background: transparent;
  border-bottom: 9px solid transparent;
}
.instruction_text::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: -6px;
  border-left: 10px solid var(--react_flow-border);
  border-top: 10px solid transparent;
  background: transparent;
} */






/**/
