

.classify_page_container .data_table_section_container
{
    width: 100%;
}

.data_option_section_container .MuiTabs-scroller .MuiTabs-flexContainer
{
    justify-content: center;
}
.save_multiple
{
  width: 27px;
  height: 24px;
  margin: 4px 15px 0px 15px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position-x: center;
  cursor: pointer;
}
