
.HomepageHeaderSection
{
  /*position: absolute;
  z-index: 1;
  left: 0;
  top: 0;*/
  width: 100%;
  height: 55px;
  color: #fff;
  box-shadow: 0px 0px 3px #fff;
  display: flex;
  background: linear-gradient(#000000, #000000,#0f2f10);
}
.HomepageHeaderSection .logo
{
  height: 55px;
  width: 150px;
  margin-left: 25px;
  background-repeat: no-repeat;
  background-position: -43px -32px;
  background-size: 115% 250%;
  background-position-x: center;
  cursor: pointer;
}
.menu {
    display: flex;
    justify-content: center;
    flex-grow: 1;
}
.menu > a
{
  margin-left: 15px;
  margin-right: 15px;
  color: #fff;
  font-weight: 100;
  font-size: 13px;
}
.user {
  width: 48px;
  height: 48px;
  margin: 2px 10px;
  background-repeat: no-repeat;
  background-position: 0px -1px;
  background-size: 214% 154%;
  background-position-x: center;
  border-radius: 38%;
  border: 2px solid #5a5959;
}
