



.credit_record_page_container{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.credit_details_contailer{
    width: 60%;
}
.credit_record_container{
    
    border-radius: 10px;
    background-color: var(--tableBody-temp);
    display: flex;
    justify-content: space-between;
    margin: 2% 0;
}

.credit_record{
    padding: 3% 2%  0px 4%
     /*
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    flex: 1;
    margin-right: 20px; */
}
.credit_record:hover{
    /* transform: translateY(-5px); */
}
.credit_heading{
    font-size: 14px;
    text-align: left;
}
.credit_value{
    font-size: 48px;
    text-align: left;
}


.users_total_record_value{
    text-align: left;
}
.credit_record_billing_container{
    width: 39%;
    background-color: var(--tableBody-temp);
    margin: 1% 0 0 1%;
    border-radius: 10px;
    padding: 1%;
}


.modal_heading_container{
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: space-between;
}
.payment_modal_heading{
    margin-left: 31%;
    font-size: 24px;
    font-weight: 600;
}