.campaign_in_charge_heading{
    font-size: var(--heading-font-size);
}

.campaign_in_charge_container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 2% 0;
    width: 95%;
}