.settings_section{
    display: contents;
    
  }
  .settings_section_title{
    margin:2% 0% 0px 15%;
  text-align: left;
  font-weight: 600;
    /* color: #454545; */
    font-size: 20px;
  }
  
  .settings_section_content_body{
    background-color: var(--container-body);
    margin: 1% 15%;
    border-radius: 10px;
    box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.1);
  }
  .settings_section_content{
    display: flex;
    justify-content: space-between;
  }
  .settings_section_content_heading{
    text-align: left;
    margin: 0px 0 15px;
  }
  .settings_section_content_title{
    font-size: 20px;
    margin: 20px 10px 0px;
  }
  .settings_section_content_sub_title{
    font-size: 15px;
    /* color: rgb(169, 169, 169); */
    margin-left: 10px;
  }

  .settings_action_icon{
    font-size: 24px;
    margin: 15px;
  }


  #settings_modal_heading{
    text-align: center;
    font-size: var(--heading-font-size);
    margin: 14px 0px;
  }
  .modal_description{
    margin: 20px 5px;
  }
  .modal_description_section{
    margin: 8px;
  }
  #setting_input_textfield{
    width: 100%;
  }