.team_details_container {
  
    display: inline-block;
    margin-top: 1%;
    width: 100%;
  }
  
  .team_record {
    background-color: var(--tableBody-temp);
    padding: 12px 30px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    margin: 14px 0;
  }
  
  .team_record_heading {
    font-weight: bold;
    font-size: 18px;
    /* color: #333; */
  }
  
  .link_value {
    padding-top: 15% ;
    font-size: 1.6rem;
    color: #007bff; 
  }
  
.team_record_heading_team_member{
  padding-top: 2%;
}
#accordion_container_for_team{
  width: 80%;
  border-radius: 10px;
  margin: 1% 10% 1%;
  background-color: var(--background-primary);
}

  
  .LinksSection {
    display: flex;
    justify-content: space-around;
    text-align: center;
    width: 80%;
    margin-left: 10%;
    margin-top: 10%;
  }
  
  .Link {
    height: auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: var(--tableBody-temp);
    transition: transform 0.3s;
  }
  
  .Link:hover {
    transform: translateY(-5px);
  }
  

  
  .LinkDescription {
    font-size: var(--text-font-size);
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .LinkButton {
    display: flex;
    justify-content: center;
  }
  
  .LinkButton > button {
    /* background-color: #3498DB; */
    /* color: var(--logo-text); */
    font-size: var(--text-font-size);
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    /* transition: background-color 0.3s; */
  }


.LinkDescription
{
  height: 120px;
  padding: 5px;

}



  