/* FAQs.css */

.faq-section {
    margin-top: 8px;
    margin-bottom: 4px;
    justify-content: center;
    align-items:center ;
    text-align: center;

  }
  
  .faq-heading {
    text-align: center;
    font-size: var(--heading-font-size);
  }
  #question-container{
    margin-left: 15rem;
    margin-right: 15rem;
  }
  
  .faq-accordion {
    border: 1px solid #eee;
    border-radius: 8px;
    margin-bottom: 8px;
    
  }
  
  .faq-question {
    font-size: var(--text-font-size);
  }
  
  .faq-answer {
    font-size: var(--text-font-size);
  }
  
  .faq-divider {
    margin-top: 4rem;
  }
  