

.client_url_selected{
  display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1% 0;
}
.client_url_heading{
  font-size: var(--heading-font-size);
    font-weight: 600;
}
.client_url_initial_loader_container{
  position: relative;
  width: 100%;
  height: 70%;
}
.client_url_text_area_container{
  /* margin: 1% ; */

}
.client_url_create_scrape_button_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 2% 0;

}
.client_url_other_button_container{
  display: flex;
  justify-content: space-between;
  margin: 2% 0;

}
.client_url_table_container{
  margin: 1% 0;
}
svg.OpenInNewIcon {
    cursor: pointer;
}
.modalContentCantainer
{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15px 0px 0px 0px;
  border-radius: 5px;
  /* border: 1px solid #b7b7b7;s */
  padding: 15px;
}
.text_area_of_link_insertion{
  background: var(--container-body);
 color: var(--text-primary);
 /* padding: 16.5px 14px;
 height: 65px;  */
 font: inherit;
 box-sizing: border-box;
 width: 100%;
 line-height: 1.5;
 padding: 14px;
 border-radius: 6px 6px 0 6px;
 border: 1px solid gray;
}
.resize-handle {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px; /* or any desired width */
  height: 100%;
  cursor: col-resize;
  z-index: 1;
}
.css-kxs4g9-MuiDataGrid-root .MuiDataGrid-iconSeparator:hover {
  cursor: col-resize;
}

.add_target_urls_heading_container {
  display: flex;
  justify-content: left;
  width: 100%;
}

.add_target_urls_heading_text {
  font-size: var(--heading-font-size);
  font-weight: 900;
}

.add_target_urls_sub_heading_container {
  display: flex;
  justify-content: left;
  width: 100%;
}

.add_target_urls_sub_heading_text {
  font-size: var(--text-font-size);
  line-height: 1.6;
  text-align: left;
}

.add_target_urls_heading_and_sub_heading_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 2% 0;
}
