.classify_page_container
{
  margin: 2% 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.classify_page_container .data_option_section_container
{
    margin: auto;
    width: fit-content;
    height: 140px;
}
.classify_page_container .data_table_section_container
{
    width: 100%;
}

.data_option_section_container .MuiTabs-scroller .MuiTabs-flexContainer
{
    justify-content: center;
}
.save_multiple
{
  width: 27px;
  height: 24px;
  margin: 4px 15px 0px 15px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position-x: center;
  cursor: pointer;
}




/*--------------------------------------------------------------------------------------------*/

/* Add your global styles here if needed */

.fileUploadContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  /*width: fit-content;*/
}

.fileUploadButton {
  margin-bottom: 20px;
}
.classify_tab_label_text{
  font-size: 13px;
}
.fileInput {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
}



.columnSelectionContainer {
  margin-top: 20px;
}

.columnSelectionItem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.loadButton {
  margin-right: 10px;
}





.uploadedFileItem {
  /*display: flex;*/
  display: inline-grid;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
  /*border: 1px solid #ccc;*/
  border-radius: 8px;
  transition: background-color 0.3s;
}


.uploadedFileItem span {
  font-weight: bold;
  margin-right: 10px;
}

.uploadedFileItem select {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
}

.uploadedFileItem select:hover,
.uploadedFileItem select:focus {
  border-color: #007bff;
}


/*--------------------------------------------------------------------------------------------*/

/**/
.classification_progress_bar{
  width: 30%;
  position: relative;
  margin: 2% 0 1% 2%;
}
.classification_progress_bar_title{
  margin: 2% 0 0% 0%;
}
