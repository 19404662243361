
:root {
  --container-height: 500px; 
}
.subscriptionBody{
  height: 100vh;
}

.subscriptionPrimaryHeading {
  font-weight: bolder;
  margin-top: 10px;
  text-align: center;
  font-size: var(--heading-font-size);
}

.subscriptionPrimaryHeadingDesc {
  font-size: var(--text-font-size);
  font-weight: 100;
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: center;
}

.subscription-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 70px 7px; /* Add negative margin to compensate for box-sizing */
}

.subscription-container-box {
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  margin: 10px; /* Adjust margin */
  max-height: var(--container-height); /* Set max-height */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  background-color: var(--container-body);
}

@media (min-width: 600px) {
  .subscription-container-box {
    width: 45%;
  }
}

@media (min-width: 900px) {
  .subscription-container-box {
    width: 30%;
    height: auto;
    font-size: 0.8rem;
  }
}

.basic-container {
  min-width: fit-content;
  height: auto;
  padding: 10px; /* Adjust padding */
}

.container-upper {
  text-align: left;
}

.subscription-container-box-primary-head {
  font-weight: bold;
  padding: 15px;
  font-size: var(--heading-font-size);
  color: #3498db;
}

.subscription-container-box-secondary-desc {
  font-size: var(--text-font-size);
  font-weight: 400;
  padding-left: 15px;
}

.subscription-price {
  font-size: var(--text-font-size);
  padding: 8px 15px;
  color: #e74c3c;
}

/* .container-lower {
  padding: 10px; 
} */
.subscription-container-desc li{
  list-style: none;
}
.subscriptionContainerDescList {
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 0px;
}

.subscription-container-desc {
  font-size: var(--text-font-size);
  font-weight: 400;
  padding: 10px;
}

.check-icon {
  margin-right: 10px;
  color: #2ecc71;
}

.feature-text {
  vertical-align: middle;
}

.next-button {
  background-color: #3498db;
  padding: 12px 20px;
  border-radius: 20px;
  font-size: var(--text-font-size);
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.next-button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.next-button:hover {
  background-color: #2980b9;
  cursor: pointer;
}

.arrow-icon {
  margin-left: 10px;
}

.extra-element {
  margin-top: 10px;
  text-align: center;
}

.extra-element p {
  font-size: var(--text-font-size);
}
